import PropTypes from 'prop-types';
import { ToastContainer } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import * as constants from "../../../constants/layout"
import { useSelector, useDispatch } from "react-redux";
import Spinners from "../../../components/Common/Spinner";
import React, { useEffect, useMemo, useState } from "react";
import DeleteModal from "../../../components/Common/DeleteModal";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import TableContainer from '../../../components/Common/TableContainer';
import { deleteMockupTest, resetStoremockuptest, getMockupTest } from "../../../store/actions";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Card, CardBody, Badge } from "reactstrap";

const MockupTstList = () => {
  document.title = "Configuration | Mockuptest";

  const store = useSelector(state => state.MockupList)
  const dispatch = useDispatch();
  const user = getLoggedInUser()
  const [submitbtn, setSubmitbtn] = useState(false)
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [blueprint, setblueprint] = useState([]);
  const [board, setBoard] = useState();
  const navigate = useNavigate();
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(true)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)

  // After api call success stop loading
  useEffect(() => {
    if (store.mockuptest_statusFlag === 1) {
      if (submitbtn) { // add or update then refresh list
        setSubmitbtn(false)
        getMockupTestapi()
        setModal(false);
      }
      if (deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        getMockupTestapi()
        setDeleteModal(false);
        setBoard('');
      }

      setLoading(false)
      dispatch(resetStoremockuptest({ statusFlag: 0 }));
    } else if (store.mockuptest_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn(false)
      }
      if (deleteTrigger) {
        setDeleteTrigger(false)
      }

      setLoading(false)
      dispatch(resetStoremockuptest({ statusFlag: 0 }));
    }
  }, [store.mockuptest_statusFlag])

  const onClickDelete = (blueprintData) => {
    setBoard(blueprintData);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteTrigger(true)
    setLoading(true)
    dispatch(deleteMockupTest({ user_id: Number(user.uid), mkid: Number(board.mkid) }))
  };
  const getMockupTestapi = () => {
    setLoading(true)
    dispatch(getMockupTest({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Mockup Test';
    getMockupTestapi()
  }, [dispatch]);

  useEffect(() => {
    setblueprint(store.mockupList)
  }, [store.mockupList])

  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        width: '8%',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.MockupTest && f.urp_type === constants.menuTypes.Special) && (
                  <Button
                    to="#"
                    className="btn btn-sm btn-soft-warning"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;

                      localStorage.setItem('isClone', '1')
                      navigate(
                        `/add-mockuptest/${blueprintData.mkid}`
                      )
                    }}
                  >
                    <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                    <UncontrolledTooltip placement="top" target="clonetooltip">
                      Clone
                    </UncontrolledTooltip>
                  </Button>
                )}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.MockupTest && f.urp_type === constants.menuTypes.Special) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;
                      localStorage.setItem('isClone', '0')
                      navigate(`/add-mockuptest/${blueprintData.mkid}`);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                )}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.MockupTest && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const blueprintData = cellProps.row.original; onClickDelete(blueprintData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>
                )}

            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
     
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Test Name ▼
          </span>
        ),
        accessorKey: 'mk_testname',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const statusflag = cellProps.row.original.statusflag;
          return (
            <div className="d-flex align-items-center">
               {cellProps.row.original.mk_testname} 
               <span style={{marginLeft:'10%'}}>
               {statusflag > 0 && (
                <Badge color="danger" className="me-2">
                  Question Shortage
                </Badge>
              )}
               </span>
             </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Board ▼
          </span>
        ),

        accessorKey: 'ex_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Board Category ▼
          </span>
        ),

        accessorKey: 'exct_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      // {
      //   header: (
      //     <span style={{ cursor: 'pointer' }}>
      //       Languages ▼
      //     </span>
      //   ),

      //   accessorKey: 'l_name',
      //   enableColumnFilter: false,
      //   enableSorting: true,
      // },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Type ▼
          </span>
        ),

        accessorKey: 'mk_type',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'u_stsid',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.mk_stsid;
          // const sts_name = cellProps.row.original.sts_name;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid == "1" ? (
                <span>

                <i className="mdi mdi-check-circle text-success me-2" id="activetooltip" style={{ fontSize: '25px' }} />
                <UncontrolledTooltip placement="top" target="activetooltip">
                       Active
                   </UncontrolledTooltip>
              </span>
              ) : (
                <span>

                  <i className="dripicons dripicons-wrong text-danger me-2" id="pendingtooltip" style={{ fontSize: '20px' }} />
                  <UncontrolledTooltip placement="top" target="pendingtooltip">
                       Inactive
                   </UncontrolledTooltip>
                </span>
              )}
            </div>
          );
        },
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Created By ▼
        </span>
        ),
        
        accessorKey: 'createdat',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                             <span>{mk_stsid}</span>

              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );

  const handleAddClick = () => {
    navigate(
      `/add-mockuptest/0`
    )
  };

  //meta title
  document.title = "Masters | Mockup test";

  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.MockupTest)
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Mockup test" /> */}
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={blueprint || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        isAddButton={canCreate}
                        buttonName="Add"
                        SearchPlaceholder="Search..."
                        pagination="pagination"
                        handleUserClick={handleAddClick}
                        // isCustomPageSize={true}

                        //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"

                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>

        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
MockupTstList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};

export default MockupTstList;