import {
    GET_SUBJECTSSUBCATEGORY_SUCCESS,
    ADD_SUBJECTSUBCATEGORY_SUCCESS,
    DELETE_SUBJECTSUBCATEGORY_SUCCESS,
    RESET_STORE_SUBJECTSUBCATEGORY,
} from "./actionTypes"

  const INIT_STATE = {
    subjectSubCatList: [],
    subjectsubcategory_statusFlag: 0
  }
  
  const Subjectsubcategory= (state = INIT_STATE, action) => {
    console.log(action.type, action.payload, 'reducer')
    switch (action.type) {
      case  GET_SUBJECTSSUBCATEGORY_SUCCESS:
        return {
          ...state,
          subjectSubCatList: action.payload.subjectSubCatList,
          subjectsubcategory_statusFlag: action.payload.statusFlag
        }
      case ADD_SUBJECTSUBCATEGORY_SUCCESS:
      return {
          ...state,
          subjectsubcategory_statusFlag: action.payload.statusFlag,
      };
      case   DELETE_SUBJECTSUBCATEGORY_SUCCESS:
        return {
            ...state,
            subjectsubcategory_statusFlag: action.payload.statusFlag,
        };
       case  RESET_STORE_SUBJECTSUBCATEGORY:
            return {
              ...state,
              subjectsubcategory_statusFlag: action.payload.statusFlag,
            };
        default:
            return state
        }
      }
      
      export default Subjectsubcategory