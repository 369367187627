// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { getUserroleList, resetUserRoleStore, deleteUserrole, setEditUserRole } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"

import { useSelector, useDispatch } from "react-redux";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";

const Userrole = () => {
    
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const store = useSelector(state => state.Userrole)
    const [modal, setModal] = useState(false);
    const [modal1, setModal1] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [userRoleList, setUserRoleList] = useState([])
    const [userRole, setUserRole] = useState('')
    const [deleteTrigger, setDeleteTrigger] = useState(false)
    const user = getLoggedInUser()  
    const [canView, setCanView] = useState(false)
    const [canCreate, setCanCreate] = useState(false)
    const [canModify, setCanModify] = useState(false)
    const [canRemove, setCanRemove] = useState(false)

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: true
        },
        validationSchema: Yup.object({
            userdisplayName: Yup.string().required("Please Enter User display Name"),
            loginID: Yup.string().required("Please Enter Login ID"),
            password: Yup.string().required("Please Enter Password"),
            confirmPassword: Yup.string()
                .oneOf([Yup.ref('password'), null], 'Passwords must match')
                .required('Please confirm your password'),
        }),
        onSubmit: (values) => {
            //   if (isEdit) {
            //     const updateOrder = {
            //       id: order ? order.id : 0,
            //       orderId: values.orderId,
            //       billingName: values.billingName,
            //       orderDate: values.orderDate,
            //       total: values.total,
            //       paymentStatus: values.paymentStatus,
            //       paymentMethod: values.paymentMethod,
            //       badgeClass: values.badgeClass,
            //     };
            //     // update order
            //     dispatch(onUpdateOrder(updateOrder));
            //     validation.resetForm();
            //   } else {
            //     const newOrder = {
            //       id: Math.floor(Math.random() * (30 - 20)) + 20,
            //       orderId: values["orderId"],
            //       billingName: values["billingName"],
            //       orderDate: values["orderDate"],
            //       total: values["total"],
            //       paymentStatus: values["paymentStatus"],
            //       paymentMethod: values["paymentMethod"],
            //       badgeClass: values["badgeClass"],
            //     };
            //     // save new order
            //     dispatch(onAddNewOrder(newOrder));
            //     validation.resetForm();
            //   }
            toggle();
        },
    });
    const [deleteModal, setDeleteModal] = useState(false);

    
    //delete icon click from list
    const onClickDelete = (data) => {
        setUserRole(data);
        setDeleteModal(true);
    };

    //Delete API calling after confirmation popup
    const handleDeleteUserRole = () => {
        setDeleteTrigger(true)
        setLoading(true)
        // dispatch(deleteUserrole({user_id: Number(user.uid), urid: Number(userRole.urid)}))
        dispatch(deleteUserrole({user_id: Number(user.uid), urid: Number(userRole.urid)}))
    };

    //Get userRole details API calling 
    const handleEditClick = (data) => {
        console.log(data, 'data')
        dispatch(resetUserRoleStore({statusFlag: 0, userRoleDetails: []}));
        navigate('/add-userrole', {state:{urid : Number(data.urid), clone_urid: '', ur_stsid: data.ur_stsid}})             
    };

    const handleCloneClick = (data) => {
        dispatch(resetUserRoleStore({statusFlag: 0, userRoleDetails: []}));
        navigate('/add-userrole', {state:{urid: '', clone_urid : Number(data.urid), ur_stsid: ''}})   
    }

    const columns = useMemo(
        () => [
            {
                header: 'Actions',
                // accessorKey: 'action',
                // enableColumnFilter: false,
                // enableSorting: true,

                width: '8%',
                // width: (cell) => cell.value.length * 10, 
                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                             {user && user.user_previleges && user.user_previleges.find(f => 
                                Number(f.urp_smid) === constants.SubmenuList.UserRole && f.urp_type === constants.menuTypes.Create) && ( 
                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-warning"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleCloneClick(orderData);
                                }}
                            >
                                <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                                <UncontrolledTooltip placement="top" target="clonetooltip">
                                    Clone
                                </UncontrolledTooltip>
                            </Button>)}
                            {user && user.user_previleges && user.user_previleges.find(f => 
                            Number(f.urp_smid) === constants.SubmenuList.UserRole && f.urp_type === constants.menuTypes.Modify) && ( 
                            <Button
                                to="#"
                                className="btn btn-sm btn-soft-info"
                                onClick={() => {
                                    const orderData = cellProps.row.original;
                                    handleEditClick(orderData);
                                }}
                            >
                                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                <UncontrolledTooltip placement="top" target="edittooltip">
                                    Edit
                                </UncontrolledTooltip>
                            </Button>)}
                            {user && user.user_previleges && user.user_previleges.find(f => 
                                Number(f.urp_smid) === constants.SubmenuList.UserRole && f.urp_type === constants.menuTypes.Remove) && ( 
                            <div
                                to="#"
                                className="btn btn-sm btn-soft-danger"
                                onClick={() => {
                                    const orderData = cellProps.row.original; onClickDelete(orderData);
                                }}>
                                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                <UncontrolledTooltip placement="top" target="deletetooltip">
                                    Delete
                                </UncontrolledTooltip>
                            </div>)}
                           
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1   

            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        User Role ▼ 
                    </span>
                ),
               
                accessorKey: 'ur_name',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        No.of Users ▼ 
                    </span>
                ),
              
                accessorKey: 'userscount',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'txt-center'
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Status ▼ 
                    </span>
                ),
                
                accessorKey: 'ur_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const ur_stsid = cellProps.row.original.ur_stsid;

                    return (
                        <div className="d-flex align-items-center">
                            {ur_stsid === 1 ? (
                                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
                            ) : (
                                <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
                            )}
                        </div>
                    );
                },
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                       Created By ▼ 
                    </span>
                ),
               
                accessorKey: 'ur_created',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: (
                  <span style={{ cursor: 'pointer' }}>
                  Updated By ▼
                </span>
                ),
                
                accessorKey: 'updated',
                // enableColumnFilter: false,
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                  const mk_stsid = cellProps.row.original.updated;
        
                  return (
                    <div className="d-flex align-items-center">
                      {mk_stsid  ? (
                                     <span>{mk_stsid}</span>
        
                      ) : (
                      <span>-</span>
                      )}
                    </div>
                  );
                },
              }
          
        ],
        []
    );

   
    const handleAddClick = () => {
        dispatch(resetUserRoleStore({statusFlag: 0, userRoleDetails: []}));
        navigate('/add-userrole', {state:{urid:''}})       
    };
    const toggle = () => {
        if (modal) {
            setModal(false);
            //   setOrder(null);
        } else {
            setModal(true);
        }
    };
    //meta title
    document.title = "Masters | User Role";

     //Get USerRole list
    const getUserroleListAPI = () => {
        setLoading(true)
        dispatch(getUserroleList({user_id: Number(user.uid)}));
    }
    
    //Initial List API calling
    useEffect(() => {
        getUserroleListAPI()
        document.getElementById('headerTitle').innerHTML='User Role';
    }, [dispatch]);


    //Get list from store and set it state values
  useEffect(() => {
    setUserRoleList(store.userRoleList)
  }, [store.userRoleList])

  //After api call success stop loading
  useEffect(() => {
    if (store.ur_role_statusFlag === 1) { 
        if(deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        getUserroleListAPI()
        setDeleteModal(false);
        setUserRole('');
      }     
      setLoading(false)
      dispatch(resetUserRoleStore({statusFlag: 0}));
    } else if (store.ur_role_statusFlag === 2) { 
        if(deleteTrigger) {
            setDeleteTrigger(false)
            setDeleteModal(false);
            setUserRole('');
        }    
      setLoading(false)
      dispatch(resetUserRoleStore({statusFlag: 0}));
    }
  }, [store.ur_role_statusFlag])

    //USer role credentials
    useEffect(() => {
        if(user && user.user_previleges) {
          console.log(user.user_previleges, 'user_previleges')
          let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.UserRole)
          if(page_menu_list) {
            setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid  ? true : false)
            setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid  ? true : false)
            setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
            setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
          }
        }
      }, [user]);

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteUserRole}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Masters" breadcrumbItem="User Role" /> */}
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <TableContainer
                                                columns={columns}
                                                data={userRoleList || []}
                                                isGlobalFilter={true}
                                                isPagination={true}
                                                isAddButton={canCreate}
                                                buttonName="Add"
                                                SearchPlaceholder="Search..."
                                                pagination="pagination"
                                                handleUserClick={handleAddClick}
                                                // isCustomPageSize={true}
                                                //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                                                buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                                                tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                theadClass="table-light"

                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                        </CardBody>
                                    </Card>
                                </Col>
                        }
                    </Row>
                </div>
            </div>
            <ToastContainer />
        </React.Fragment>
    );
}
Userrole.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default Userrole;