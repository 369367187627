// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import TopicListModal from "../../../components/Common/TopicModal"
import SubjectSubCategoryModal from "../../../components/Common/SubjectSubCategoryModal"
import Select from "react-select";
import { getsubject, getmasterlist, addsubject, resetsubject, deletesubject } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../../constants/layout"
import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { logoutUser } from "store/auth/login/actions";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";


const SubjectList = () => {
  document.title = "Masters | Subject";

  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const store = useSelector(state => state.Subject)
  const masterstore = useSelector(state => state.ExamBoardCategory)
  const user = getLoggedInUser()
  const [modal, setModal] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [submitbtn, setSubmitbtn] = useState('')
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [masterList, setmasterList] = useState([]);
  const [modal1, setModal1] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [subject, setSubject] = useState(null);
  const [subjectlist, setsubjectlist] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [board, setBoard] = useState();
  const [selectedSubject, setselectedSubject] = useState([]);
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  const [topicModal, setTopicModal] = useState(false);
  const [topicList, setTopicList] = useState([])
  const [subjectDetails, setSubjectDetails] = useState('')
  const [subjectSubCatModal, setSubjectSubCatModal] = useState(false);
  const [subjectSubCatList, setSubjectSubCatList] = useState([])
  const [selectedPaper, setselectedPaper] = useState(null);
  const boardref = useRef(null);

  const [paperList, setPaperList] = useState([])
  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      subjectID: (subject && subject.subjectID) || '',
      subjectName: (subject && subject.subjectName) || '',
      //selectedSubject:(subject && subject.selectedSubject) ||[],
      selectedPaper: (subject && subject.selectedPaper) || null

    },
    validationSchema: Yup.object({
      subjectName: Yup.string().required("Please Enter Subject Name"),
      selectedPaper: Yup.object().required("Please select examination  paper"),
      // selectedSubject: Yup.array().min(1, "Please select the language")
      // .required("Please select the language"),


    }),
    onSubmit: (values) => {
      // setSubmitbtn(true)
      setLoading(true)
      if (isEditing) { //update
        const newaddsubject = {
          user_id: Number(user.uid),
          s_name: values.subjectName,
          process_type: 'update',
          ex_paper_id: Number(selectedPaper?.value),
          s_id: board.sid,
        };
        // update new Exam Board
        disableButton()
        dispatch(addsubject(newaddsubject));
      } else { //add
        const newaddsubject = {
          user_id: Number(user.uid),
          s_name: values.subjectName,
          process_type: 'save',
          ex_paper_id: Number(selectedPaper?.value),
        };
        // save new Exam Board
        disableButton()
        dispatch(addsubject(newaddsubject));

      }

      // toggle();
    },
  });
  const [deleteModal, setDeleteModal] = useState(false);

  // After api call success stop loading
  useEffect(() => {
    enableButton()
    if (store.subject_statusFlag === 1) {
      if (submitbtn) { // add or update then refresh list        
        if (submitbtn !== 'savecontinue') { // If it not savecontinue close popup
          setModal(false);
          boardref.current.focus()
          // setInputFocus(false)
        }
        // setBoard('')
        validation.resetForm();
        const event = {
          target: {
            name: 'setselectedPaper', value: {
              label: setselectedPaper.label,
              value: setselectedPaper.value,
            }
          }
        };
        // inputRef.current.focus();
        // setInputFocus(false);
        validation.setFieldValue("selectedPaper", setselectedPaper)

        validation.handleChange(event);
        getsubList()
        setSubmitbtn('')
        // setselectedPaper(null)

      }
      if (deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        getsubList()
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resetsubject({ statusFlag: 0 }));
          // Set autofocus after value reset
          if (modal && inputRef.current) {
            boardref.current.focus();
          }
    } else if (store.subject_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn('')
      }
      if (deleteTrigger) {
        setDeleteTrigger(false)
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resetsubject({ statusFlag: 0 }));
    }
  }, [store.subject_statusFlag])

  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const onClickDelete = (subjectData) => {
    setBoard(subjectData);
    setDeleteModal(true);
  };
  const getsubList = () => {
    setLoading(true)
    dispatch(getsubject({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getsubList()
  }, [dispatch]);

  useEffect(() => {
    setsubjectlist(store.subjectList)
  }, [store.subjectList])

  const getmasterlistapi = () => {
    setLoading(true)
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getmasterlistapi()
    document.getElementById('headerTitle').innerHTML = 'Subject';
  }, [dispatch]);

  useEffect(() => {
    if (masterstore.masterlist && masterstore.masterlist.languageList)
      setmasterList(masterstore.masterlist.languageList)
    if (masterstore.masterlist && masterstore.masterlist.examPaperList)
      setPaperList(masterstore.masterlist.examPaperList)
  }, [masterstore.masterlist])

  const handleDeleteOrder = () => {
    setDeleteTrigger(true)
    setLoading(true)
    dispatch(deletesubject({ user_id: Number(user.uid), s_id: Number(board.sid) }))
  };

  const handleSelectPaper = (selectedOption) => {
    setselectedPaper(selectedOption);
  };
  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.Subject && f.urp_type === constants.menuTypes.Modify) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const subjectData = cellProps.row.original;
                      handleEditClick(subjectData);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>)}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.Subject && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const subjectData = cellProps.row.original; onClickDelete(subjectData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Paper ▼
          </span>
        ),

        accessorKey: 'exampaper',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Subject Name ▼
          </span>
        ),

        accessorKey: 'subjectname',
        enableColumnFilter: false,
        enableSorting: true,
      },

      {
        header: (
          <div style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column' }}>
            <span>No. of Subjects</span>
            <span>Subcategories ▼</span>
          </div>
        ),

        accessorKey: 'subcategorycount',
        // width: '18%',
        className: 'txt-center',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end" style={{ cursor: 'pointer' }}>
              {cellProps?.row?.original?.subcategorycount && Number(cellProps?.row?.original?.subcategorycount) !== 0 ? (
                <div style={{ textDecoration: 'underline' }} onClick={() => {
                  const subjectData = cellProps.row.original;
                  getSubjectSubCategoryList(subjectData);
                }}>
                  {cellProps?.row?.original?.subcategorycount}
                </div>
              ) : (
                <div>{cellProps?.row?.original?.subcategorycount}</div>
              )}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            No. of Topics ▼
          </span>
        ),

        accessorKey: 'topicscount',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'txt-center',
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end" style={{ cursor: 'pointer' }}>
              {cellProps?.row?.original?.topicscount && Number(cellProps?.row?.original?.topicscount) !== 0 ? (
                <div style={{ textDecoration: 'underline' }} onClick={() => {
                  const subjectData = cellProps.row.original;
                  getTopicList(subjectData);
                }}>
                  {cellProps?.row?.original?.topicscount}
                </div>
              ) : (
                <div>{cellProps?.row?.original?.topicscount}</div>
              )}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            No.of Questions ▼
          </span>
        ),

        accessorKey: 'questioncount',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'txt-center'
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'createdat',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                             <span>{mk_stsid}</span>

              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }
      


    ],
    []
  );

  const handleAddClick = () => {
    enableButton()
    validation.resetForm();
    setIsEditing(false)
    setBoard('')

    setselectedPaper(null)
    toggle();
  };
  const handleEditClick = (subjectData) => {
    enableButton()
    validation.resetForm();
    setBoard(subjectData);
    toggle();
    setIsEditing(true);

    // Update this part to set the selected Subject and languages
    validation.setValues({
      subjectID: subjectData.subjectID,
      subjectName: subjectData.subjectname,
      selectedPaper: {
        label: subjectData.exampaper,
        value: subjectData.expid

      }
    });
    setselectedPaper({
      label: subjectData.exampaper,
      value: subjectData.expid

    })


  };
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  const toggleTopic = () => {
    setTopicModal(!topicModal);
  };

  const toggleSubjectSubCategory = () => {
    setSubjectSubCatModal(!subjectSubCatModal)
  }

  async function getTopicList(data) {
    // setLoading(true)
    var req_data = {
      user_id: Number(user.uid),
      s_id: Number(data.sid)
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'master/getSubjectTopics', req_data, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          await getTopicList() // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1?.data?.body?.SubjectTopics) {
      toggleTopic();
      setTopicList(response1?.data?.body?.SubjectTopics)
      setSubjectDetails(data)
    } else {
      setTopicList([])
      setSubjectDetails('')
    }
    // setLoading(false)
  }

  async function getSubjectSubCategoryList(data) {
    // setLoading(true)
    var req_data = {
      user_id: Number(user.uid),
      s_id: Number(data.sid)
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'master/getSubjectSubcategory', req_data, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          await getSubjectSubCategoryList() // calling same function again getting refresh token
        }
      }
    }

    if (response1 && response1.data && response1?.data?.body?.SubCategoryList) {
      toggleSubjectSubCategory();
      setSubjectSubCatList(response1?.data?.body?.SubCategoryList)
      setSubjectDetails(data)
    } else {
      setSubjectSubCatList([])
      setSubjectDetails('')
    }
    // setLoading(false)
  }


  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      console.log(user.user_previleges, 'user_previleges')
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.Subject)
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <TopicListModal
        show={topicModal}
        topicList={topicList}
        subjectDetails={subjectDetails}
        from={'subject'}
        onCloseClick={() => setTopicModal(false)}
      />
      <SubjectSubCategoryModal
        show={subjectSubCatModal}
        subjectSubCatList={subjectSubCatList}
        subjectDetails={subjectDetails}
        onCloseClick={() => setSubjectSubCatModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={subjectlist || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        isAddButton={canCreate}
                        buttonName="Add"
                        SearchPlaceholder="Search..."
                        pagination="pagination"
                        handleUserClick={handleAddClick}
                        // isCustomPageSize={true}
                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"

                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEditing ? "Edit Subject" : "Add Subject"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedPaper}
                        onChange={(selectedOption) => {
                          handleSelectPaper(selectedOption);
                          validation.setFieldValue("selectedPaper", selectedOption)
                        }}
                        options={paperList.map((paper) => ({
                          label: paper.exp_name,
                          value: paper.expid,
                        }))}
                        ref={inputRef}
                        validate={{
                          required: { value: true },
                        }}

                        className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}


                        invalid={
                          validation.touched.selectedPaper && validation.errors.selectedPaper ? true : false
                        }
                      />
                      {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                        <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Subject Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        name="subjectName"
                        type="text"
                        placeholder="Subject"
                        validate={{
                          required: { value: true },
                        }}
                        innerRef={boardref}
                        maxLength={constants.textboxLength.s_name}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.subjectName || ""}
                        invalid={
                          validation.touched.subjectName && validation.errors.subjectName ? true : false
                        }
                        
                      />
                      {validation.touched.subjectName && validation.errors.subjectName ? (
                        <FormFeedback type="invalid">{validation.errors.subjectName}</FormFeedback>
                      ) : null}
                    </div>

                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success"
                        type="submit"
                        className="save-user"
                        disabled={isButtonDisabled}
                        onClick={() => {
                          setSubmitbtn('save')
                        }}
                      >
                        {!!isEditing ? "Update" : "Save"}
                      </Button>
                      {!isEditing && (
                        <Button color="primary" type="submit" className="save-and-continue-user"
                          onClick={() => {
                            setSubmitbtn('savecontinue')
                          }}
                          disabled={isButtonDisabled}
                          style={{ marginLeft: '10px' }}>
                          Save and Continue
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>


        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
SubjectList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default SubjectList;