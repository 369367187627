import React, { useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";


// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import withRouter from "components/Common/withRouter";
import { Link } from "react-router-dom";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import * as constants from "../../constants/layout"
import './sidebar.scss'
//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = props => {
  const ref = useRef();
  const user = getLoggedInUser()
  const activateParentDropdown = useCallback((item) => {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];

    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }, []);

  const removeActivation = (items) => {
    for (var i = 0; i < items.length; ++i) {
      var item = items[i];
      const parent = items[i].parentElement;

      if (item && item.classList.contains("active")) {
        item.classList.remove("active");
      }
      if (parent) {
        const parent2El =
          parent.childNodes && parent.childNodes.lenght && parent.childNodes[1]
            ? parent.childNodes[1]
            : null;
        if (parent2El && parent2El.id !== "side-menu") {
          parent2El.classList.remove("mm-show");
        }

        parent.classList.remove("mm-active");
        const parent2 = parent.parentElement;

        if (parent2) {
          parent2.classList.remove("mm-show");

          const parent3 = parent2.parentElement;
          if (parent3) {
            parent3.classList.remove("mm-active"); // li
            parent3.childNodes[0].classList.remove("mm-active");

            const parent4 = parent3.parentElement; // ul
            if (parent4) {
              parent4.classList.remove("mm-show"); // ul
              const parent5 = parent4.parentElement;
              if (parent5) {
                parent5.classList.remove("mm-show"); // li
                parent5.childNodes[0].classList.remove("mm-active"); // a tag
              }
            }
          }
        }
      }
    }
  };

  const path = useLocation();
  const activeMenu = useCallback(() => {
    const pathName = path.pathname;
    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    removeActivation(items);

    for (let i = 0; i < items.length; ++i) {
      if (pathName === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  }, [path.pathname, activateParentDropdown]);

  useEffect(() => {
    ref.current.recalculate();
  }, []);

  useEffect(() => {
    new MetisMenu("#side-menu");
    activeMenu();
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
    activeMenu();
  }, [activeMenu]);

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu" className="sidebardesign">
          <ul className="metismenu list-unstyled" id="side-menu">
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.Masters) && (
              <li>
                <Link to="/dashboard" className="has-arrow">
                  <i className="dripicons-align-justify"></i>
                  <span><strong>{props.t("Master")}</strong></span>
                </Link>
                <ul className="sub-menu">
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamBoard)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-boards">{props.t("Exam Board")}</Link>
                    </li>
                  )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamBoardCategory)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-board-categories">{props.t("Exam Board Category")}</Link>
                    </li>
                  )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamPapers)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-exam-papers">{props.t("Exam Papers")}</Link>
                    </li>
                  )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.Subject)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-subjects">
                        {props.t("Subject")}
                      </Link>
                    </li>
                  )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.SubjectCategory)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-subject-categories">{props.t("Subject Subcategory")}</Link>
                    </li>
                  )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.Topic)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-topics">{props.t("Topic")}</Link>
                    </li>
                  )}
                  
                  {/* {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.StudyMaterial)?.urp_type === constants.menuTypes.View && (

                    <li>
                      <Link to="/list-studymaterial" >

                        {props.t("Study Materials")}
                      </Link>
                    </li>
                  )} */}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.UserMaster)?.urp_type === constants.menuTypes.View && (

                    <li>
                      <Link to="/list-usermaster">{props.t("User Master")}</Link>
                    </li>
                  )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.UserRole)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-userrole">{props.t("User Role")}</Link>
                    </li>


                  )}
                    {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.StudentMaster)?.urp_type === constants.menuTypes.View && (
                    <li>
                      <Link to="/list-StudentMaster">{props.t("Student Master")}</Link>
                    </li>
                  )}

                </ul>
              </li>)}
              {/* {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.QA)?.urp_type === constants.menuTypes.View && ( */}
              
              
              {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.StudyMaterial)?.urp_type === constants.menuTypes.View && (

              <li>
                      <Link to="/list-studymaterial" >
                      <i className="bx bx-book-open smgroup me-2"></i>                  
                  <span><strong>{props.t("Study Materials")}</strong></span>
                </Link>
              </li>
            )}
                  {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.Configuration)?.urp_type === constants.menuTypes.View && (
            <li>
              <Link to="/#" className="has-arrow">
                <i className="dripicons-gear"></i>
                <span><strong>{props.t("Configuration")}</strong></span>
              </Link>
              <ul className="sub-menu">
                {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.ExamBluePrint)?.urp_type === constants.menuTypes.View && (
                  <li>
                    <Link to="/list-configuration">{props.t("Exam Blue Print")}</Link>
                  </li>
                )}
                 { user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_smid) === constants.SubmenuList.MockupTest)?.urp_type === constants.menuTypes.View && ( 
                <li>
                  <Link to="/list-mockuptest">{props.t("Mockup Test")}</Link>
                </li>
                 )} 
              </ul>
            </li>
                  )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.QA)?.urp_type === constants.menuTypes.View && (
              <li>
                <Link to="/questions" >
                  <i className="bx bx-file me-2"></i>
                  <span><strong>{props.t("Q&A")}</strong></span>
                </Link>
              </li>
            )}
            {user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.GeneralSettings)?.urp_type === constants.menuTypes.View && (
              <li>
                <Link to="/general-settings" >
                  <i className="bx bx-wrench font-size-16 align-middle me-1" />
                  <span><strong>{props.t("General Settings")}</strong></span>
                </Link>
              </li>
             )} 
            {/* { user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.ExamBluePrint)?.urp_type === constants.menuTypes.View && (
            <li>               
              <Link to="/list-configuration" >
                <span><strong>{props.t("Exam Blue Print")}</strong></span>
              </Link>              
              <Link to="/list-mockuptest" >
                <span><strong>{props.t("Mockup Test")}</strong></span>
              </Link>              
            </li>
            )} */}
            {/* { user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.MockupTest)?.urp_type === constants.menuTypes.View && ( */}
            {/* <li>               
              <Link to="/list-mockuptest" >
                <i className="dripicons-gear"></i>
                <span><strong>{props.t("Mockup Test")}</strong></span>
              </Link>              
            </li> */}
            {/* )} */}
            {/* { user && user.user_previleges && user.user_previleges.find(p => Number(p.urp_mid) === constants.mainMenuList.QA)?.urp_type === constants.menuTypes.View && (
            <li>
            <Link to="/questions">
              <i className="mdi mdi-android-messages"></i> 
              <span><strong>{props.t("Q&A")}</strong></span>
            </Link>              
          </li>         
            )} */}
            {/* <li>               
            <Link to="/list-studymaterial" >
              <i className="dripicons-gear"></i>
              <span><strong>{props.t("Study Material")}</strong></span>
            </Link>                        
          </li> */}
            {/* <li>
          <Link to="/online-test" >
              <i className="dripicons-vibrate"></i>
              <span><strong>{props.t("Online Test")}</strong></span>
            </Link>   
          </li> */}
            {/* <li> 
          <Link to="/general-settings" >
              <i className="dripicons-vibrate"></i>
              <span><strong>{props.t("General Settings")}</strong></span>
            </Link>   
          </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
