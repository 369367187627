import React, { useEffect, useState, useRef } from 'react';
import { Container, Button, Card, Row, Label, Col, Input, CardBody, Form, Collapse } from 'reactstrap';
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
// import JobFilter from './JobFilter';
import QuestionData from './QuestionData';
import { getqamasterlist } from "../../store/actions";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../constants/layout"

const QuestionList = () => {
  const navigate = useNavigate();
  document.title = "Question & Answers";

  const dispatch = useDispatch();
  const store = useSelector(state => state.ExamBoardCategory)

  const user = getLoggedInUser()
  const [isLoading, setLoading] = useState(false);
  const flatpickrRef = React.useRef(null);

  const [optionBoardName, setOptionBoardName] = useState([]);
  const [optionSubjectName, setOptionSubjectName] = useState([]);
  const [optionAllSubjectName, setOptionAllSubjectName] = useState([]);
  const [optionExamPaper, setOptionExamPaper] = useState([]);
  const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);



  const [optionTopicName, setOptionTopicName] = useState([]);
  const [optionAllTopicName, setOptionAllTopicName] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  const [approveStatus, setApproveStatus] = useState([
    {
      label: "All",
      value: 0
    },
    {
      label: "Approved",
      value: 1,
    },
    {
      label: "Pending",
      value: 3
    }]);
  const [selectedBoardName, setselectedBoardName] = useState({
    label: "All",
    value: 0
  });
  const [selectedTopicName, setselectedTopicName] = useState({
    label: "All",
    value: 0
  });
  const [selectedSubjectName, setselectedSubjectName] = useState({
    label: "All",
    value: 0
  });
  const [selectedExamPaper, setselectedExamPaper] = useState({
    label: "All",
    value: 0
  });

  const [selectedStatus, setselectedStatus] = useState({
    label: "All",
    value: 0
  });
  const [selectedApproveStatus, setselectedApproveStatus] = useState({
    label: "All",
    value: 0
  });
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [filterData, setFilterData] = useState({
    selectedBoardName: 0,
    selectedSubjectName: 0 - 0,
    selectedTopicName: 0,
    selectedStatus: 0,
    selectedApproveStatus: 0,
    selectedExamPaper: 0
  });
  const [searchVal, setSearchVal] = useState('');
  const [searchData, setSearchData] = useState('');
  const customStyles = {
    option: (styles, state, data) => { // fixed
      return {
        ...styles,
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
            ? '#a6b0cf57'
            : state.isFocused
              ? '#556ee6'
              : 'white',

        color: state.isDisabled
          ? '#ccc'
          : state.isSelected
            ? 'black'
            : state.isFocused
              ? 'white'
              : 'black',

        cursor: state.isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? '#556ee6'
              : '#556ee6'
            : undefined,
        },
        fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
      };
    }
  };
  // useEffect(() => {
  //   const disableBackButton = (event) => {
  //     window.history.pushState(null, document.title, window.location.href);
  //     event.preventDefault();
  //   };

  //   window.addEventListener('popstate', disableBackButton);

  //   return () => {
  //     window.removeEventListener('popstate', disableBackButton);
  //   };
  // }, []);


  // const [questionData,setQuestionData] = useState([]);
  const toggleFilter = () => setIsFilterOpen(!isFilterOpen);
  function handleSelectBoardName(selectedBoardName) {
    setselectedBoardName(selectedBoardName);

    if (selectedBoardName?.value == '0' || selectedBoardName == null || selectedBoardName == undefined) {
      setOptionExamPaper(optionAllExamPaper)
    }
    else {
      var filtered_exampaper_option = optionAllExamPaper.map(e => {
        var exctid_arr = e?.exctid?.split(',');
        if (exctid_arr?.length > 0) {
          if (exctid_arr?.includes(selectedBoardName.value)) {
            return e;
          }
        }

      })
      filtered_exampaper_option = filtered_exampaper_option.filter(e => e != undefined);
      setOptionExamPaper(filtered_exampaper_option)

    }
    setselectedExamPaper({
      label: "All",
      value: 0
    })

  }
  const handleSelectPaper = (varselectedPaper) => {
    setselectedExamPaper(varselectedPaper)
    if (varselectedPaper?.value == '0' || varselectedPaper == null || varselectedPaper == undefined) { // if select all option for board, need to checko only exam paper 
      setOptionTopicName(optionAllTopicName)
      setOptionSubjectName(optionAllSubjectName)
    }

    else {
      filter_exampaper_subject(varselectedPaper)
      filter_exampaper_topic(varselectedPaper)
    }
    setselectedSubjectName({
      label: "All",
      value: 0
    })
    setselectedTopicName({
      label: "All",
      value: 0
    })



  };
  function filter_exampaper_subject(varselectedPaper) {
    var filtered_subject_option = optionAllSubjectName.map(e => {
      var exctid_arr = e?.exctid?.split(',');

      if (exctid_arr?.length > 0) {
        if (selectedBoardName?.value == '0' || selectedBoardName == null || selectedBoardName == undefined) { // if select all option for board, need to checko only exam paper 
          if (e.s_expid == varselectedPaper?.value) {
            return e;
          }
        }
        else {
          if (exctid_arr.includes(selectedBoardName?.value) && e.s_expid == varselectedPaper.value) {
            return e;
          }
        }
      }

    })
    filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
    setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...filtered_subject_option])

  }
  function filter_exampaper_topic(varselectedPaper) {
    var filtered_topic_option = optionAllTopicName.filter(e => e.t_expid == varselectedPaper.value);
    filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
    setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])
  }

  function handleSelectTopicName(selectedTopicName) {
    setselectedTopicName(selectedTopicName);
  }

  function handleSelectSubjectName(varselectedSubjectName) {

    if (varselectedSubjectName?.label != 'All' && varselectedSubjectName != null && varselectedSubjectName != undefined) {// if select all option for board, need to checko only exam paper 
      handleSelectPaper(selectedExamPaper)
      const [sid, sscid] = varselectedSubjectName.value.split('-');
      var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == sid && e.subcategoryid == sscid);
      filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])
    }
    else {

      handleSelectPaper(selectedExamPaper)
    }

    setselectedSubjectName(varselectedSubjectName);
    setselectedTopicName({
      label: "All",
      value: 0
    })

  }



  function handleSelectStatus(selectedStatus) {
    setselectedStatus(selectedStatus);
  }
  function handleSelectApprove(selectedStatus) {
    setselectedApproveStatus(selectedStatus);
  }

  const getqamasterlistapi = () => {
    setLoading(true)
    dispatch(getqamasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    document.getElementById('headerTitle').innerHTML = 'Q&A';
    setFilterData({
      selectedBoardName: 0,
      selectedTopicName: 0,
      selectedSubjectName: 0 - 0,
      selectedStatus: 0,
      selectedApproveStatus: 0,
      selectedExamPaper: 0

    })
    setSearchData('')
    getqamasterlistapi()

  }, [dispatch]);

  const handlesearch = (e) => {
    setSearchVal(e.target.value);
  };

  const onSearch = () => {
    setSearchData(searchVal)
  }

  useEffect(() => {
    console.log('appr', approveStatus)
    if (store.masterlist && store.masterlist.board_categoryList)
      setOptionBoardName([{ categoryname: "All", exctid: "0" }, ...store.masterlist.board_categoryList])
    if (store.masterlist && store.masterlist.conf_subject_subcategoryList) {
      setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...store.masterlist.conf_subject_subcategoryList])
      setOptionAllSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...store.masterlist.conf_subject_subcategoryList])
    }

    if (store.masterlist && store.masterlist.topicList) {
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...store.masterlist.topicList])
      setOptionAllTopicName([{ topicname: "All", topicid: "0" }, ...store.masterlist.topicList])
    }

    if (store.masterlist && store.masterlist.examPaperList) {

      setOptionExamPaper([{ exp_name: "All", expid: "0" }, ...store.masterlist.examPaperList])
      setOptionAllExamPaper([{ exp_name: "All", expid: "0" }, ...store.masterlist.examPaperList])


    }

    if (store.masterlist && store.masterlist.statusList)
      setOptionStatus([{ statusname: "All", statusid: "0" }, ...store.masterlist.statusList])

  }, [store.masterlist])


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          {/* <Breadcrumbs title="Q&A" breadcrumbItem="Q&A" /> */}


          <Row>
            <Col lg={12}>
              <Card className="job-filter">
                <CardBody>
                  <Form>

                    <Row className="g-3">
                      <Col lg={4} >
                        <div className='hstack gap-3' style={{ justifyContent: 'end' }}>
                          <Input
                            name="search"
                            type="text"
                            placeholder="Search..."
                            onChange={handlesearch}
                            value={searchVal || ""}
                            autoFocus

                          />
                          <a href="#" onClick={onSearch} className="btn btn-secondary h-100">
                            <i className="bx bx-search-alt align-middle"></i></a>
                        </div>
                      </Col>
                      <Col lg={8} className="text-end">

                        <Row className="text-end">
                          {user && user.user_previleges && user.user_previleges.find(f =>
                            Number(f.urp_mid) === constants.mainMenuList.QA && f.urp_type === constants.menuTypes.Create) && (

                              <div className='hstack gap-3' style={{ justifyContent: 'end' }}>
                                {/* <span style={{marginLeft:8, marginRight:8, fontWeight:'bold'}}>Total Questions: 8787</span> */}
                                <Button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    localStorage.setItem('isClone', '0')
                                    navigate(
                                      `/add-questions/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button>

                                <a href="#" onClick={toggleFilter} className="btn btn-secondary h-100">
                                  <i className="bx bx-filter-alt align-middle"></i></a>
                              </div>
                            )}
                          {/* <div className="position-relative h-100 hstack gap-3">
                          <a href="#" onClick={toggleFilter} className="btn btn-secondary h-100">
                            <i className="bx bx-filter-alt align-middle"></i></a>
                        </div> */}
                        </Row>
                      </Col>


                      <Collapse isOpen={isFilterOpen} id="collapseExample">
                        <Row>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Exam Board Name & Category</Label>
                              <Select
                                value={selectedBoardName}
                                onChange={(selectedOption) => {
                                  handleSelectBoardName(selectedOption);
                                }}
                                options={optionBoardName.map((option) => ({
                                  label: option.categoryname,
                                  value: option.exctid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Exam Paper</Label>
                              <Select
                                value={selectedExamPaper}
                                onChange={(selectedOption) => {
                                  handleSelectPaper(selectedOption);
                                }}
                                options={optionExamPaper.map((option) => ({
                                  label: option.exp_name,
                                  value: option.expid,
                                }))}

                                className="select2-selection"

                              />


                            </div>
                          </Col>

                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Subject & Sub Category</Label>
                              <Select
                                value={selectedSubjectName}
                                onChange={handleSelectSubjectName}
                                options={optionSubjectName.map(option => ({
                                  label: option.s_name,
                                  value: `${option.sid}-${option.sscid}`,
                                }))}
                                styles={customStyles}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div className="mb-3">
                              <Label>Topic</Label>
                              <Select
                                value={selectedTopicName}
                                onChange={handleSelectTopicName}
                                options={optionTopicName.map(option => ({
                                  label: option.topicname,
                                  value: option.topicid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          {/* <Col lg={3}>
                            <div className="mb-3">
                              <Label>Topic</Label>
                              <Select
                                value={selectedTopicName}
                                onChange={handleSelectTopicName}
                                options={optionTopicName.map(option => ({
                                  label: option.topicname,
                                  value: option.topicid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col> */}
                          {/* <Col lg={2}>
                            <div className="mb-3">
                              <Label>Status</Label>
                              <Select
                                value={selectedStatus}
                                onChange={(selectedOption) => {
                                  handleSelectStatus(selectedOption);
                                }}
                                options={optionStatus.map((option) => ({
                                  label: option.statusname,
                                  value: option.statusid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col> */}

                        </Row>
                        <Row>
                        
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Status</Label>
                              <Select
                                value={selectedStatus}
                                onChange={(selectedOption) => {
                                  handleSelectStatus(selectedOption);
                                }}
                                options={optionStatus.map((option) => ({
                                  label: option.statusname,
                                  value: option.statusid,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Approve</Label>
                              <Select
                                value={selectedApproveStatus}
                                onChange={(selectedOption) => {
                                  handleSelectApprove(selectedOption);
                                }}
                                options={approveStatus.map((option) => ({
                                  label: option.label,
                                  value: option.value,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col>
                          <Col lg={2} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => setFilterData({
                                    selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                    selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                    selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0 - 0,
                                    selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                    selectedApproveStatus: selectedApproveStatus ? selectedApproveStatus.value : 0,
                                    selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0

                                  })}
                                >
                                  Apply
                                </Button>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    setFilterData({
                                      selectedBoardName: 0,
                                      selectedTopicName: 0,
                                      selectedSubjectName: 0 - 0,
                                      selectedStatus: 0,
                                      selectedApproveStatus: 0,
                                      selectedExamPaper: 0

                                    })

                                    setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...optionAllSubjectName])

                                    setOptionTopicName([{ topicname: "All", topicid: "0" }, ...optionAllTopicName])
                                    setOptionExamPaper([{ exp_name: "All", expid: "0" }, ...optionAllExamPaper])


                                    setselectedBoardName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedTopicName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedSubjectName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedApproveStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedExamPaper({
                                      label: "All",
                                      value: 0
                                    })
                                  }

                                  }
                                >
                                  Clear
                                </Button>
                              </div>
                            </Col>
                        </Row>

                        <Row>
{/* 
                          <Col lg={2}>
                            <div className="mb-3">
                              <Label>Approve</Label>
                              <Select
                                value={selectedApproveStatus}
                                onChange={(selectedOption) => {
                                  handleSelectApprove(selectedOption);
                                }}
                                options={approveStatus.map((option) => ({
                                  label: option.label,
                                  value: option.value,
                                }))}
                                className="select2-selection"
                              />
                            </div>
                          </Col> */}

                          {/* <div className='applybutton'>

                            <Col lg={2} >
                              <div className="d-flex flex-wrap gap-2 justify-content-start">
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => setFilterData({
                                    selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                    selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                    selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0 - 0,
                                    selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                    selectedApproveStatus: selectedApproveStatus ? selectedApproveStatus.value : 0,
                                    selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0

                                  })}
                                >
                                  Apply
                                </Button>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    setFilterData({
                                      selectedBoardName: 0,
                                      selectedTopicName: 0,
                                      selectedSubjectName: 0 - 0,
                                      selectedStatus: 0,
                                      selectedApproveStatus: 0,
                                      selectedExamPaper: 0

                                    })

                                    setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...optionAllSubjectName])

                                    setOptionTopicName([{ topicname: "All", topicid: "0" }, ...optionAllTopicName])
                                    setOptionExamPaper([{ exp_name: "All", expid: "0" }, ...optionAllExamPaper])


                                    setselectedBoardName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedTopicName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedSubjectName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedApproveStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedExamPaper({
                                      label: "All",
                                      value: 0
                                    })
                                  }

                                  }
                                >
                                  Clear
                                </Button>
                              </div>
                            </Col>
                          </div> */}
                        </Row>
                      </Collapse>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>



          {/* <JobFilter setJobGrid={setJobGrid} jobData={jobsGridData} /> */}
          <QuestionData filterData={filterData} searchData={searchData} />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default QuestionList;