// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { getblueprint, deleteblueprint, resetStoreblueprint } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../../constants/layout"


//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";


const ConfigurationList = () => {
  document.title = "Configuration | BluePrint";

  const store = useSelector(state => state.Blueprint)
  const dispatch = useDispatch();
  const user = getLoggedInUser()
  const [submitbtn, setSubmitbtn] = useState(false)
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [blueprint, setblueprint] = useState([]);
  const [selectedCid, setSelectedCid] = useState(null);
  const [board, setBoard] = useState();
  const navigate = useNavigate();
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)

  // After api call success stop loading
  useEffect(() => {
    if (store.blueprint_statusFlag === 1) {
      if (submitbtn) { // add or update then refresh list
        setSubmitbtn(false)
        getblueprintapi()
        setModal(false);
        validation.resetForm();
      }
      if (deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        getblueprintapi()
        setDeleteModal(false);
        setBoard('');
      }

      setLoading(false)
      dispatch(resetStoreblueprint({ statusFlag: 0 }));
    } else if (store.blueprint_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn(false)
      }
      if (deleteTrigger) {
        setDeleteTrigger(false)
      }

      setLoading(false)
      dispatch(resetStoreblueprint({ statusFlag: 0 }));
    }
  }, [store.blueprint_statusFlag])

  const onClickDelete = (blueprintData) => {
    setBoard(blueprintData);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteTrigger(true)
    setLoading(true)
    dispatch(deleteblueprint({ user_id: Number(user.uid), cid: Number(board.cid) }))
  };

  const getblueprintapi = () => {
    setLoading(true)
    dispatch(getblueprint({ user_id: Number(user.uid) }));
  }

  //   const handleCloneClick = (data) => {
  //     dispatch(resetUserRoleStore({statusFlag: 0, bluePrintList: []}));
  //     navigate('/add-configuration', {state:{cid: '', clone_urid : Number(data.cid)}})   
  // }

  useEffect(() => {
    getblueprintapi()
    document.getElementById('headerTitle').innerHTML='Exam Blue Print';
  }, [dispatch]);

  useEffect(() => {
    setblueprint(store.bluePrintList)
  }, [store.bluePrintList])

  
  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBluePrint && f.urp_type === constants.menuTypes.Create) && (
                  <Button
                    to="#"
                    className="btn btn-sm btn-soft-warning"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;

                      localStorage.setItem('isClone', '1')
                      navigate(
                        `/add-configuration/${blueprintData.cid}`
                      )
                    }}
                  >
                    <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                    <UncontrolledTooltip placement="top" target="clonetooltip">
                      Clone
                    </UncontrolledTooltip>
                  </Button>)}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBluePrint && f.urp_type === constants.menuTypes.Modify) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;

                      localStorage.setItem('isClone', '0')
                      navigate(`/add-configuration/${blueprintData.cid}`);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>)}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBluePrint && f.urp_type === constants.menuTypes.Remove) && (
                  <Link
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const blueprintData = cellProps.row.original; onClickDelete(blueprintData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </Link>)}

            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Configuration Name ▼
          </span>
        ),
        accessorKey: 'c_name',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const statusflag = cellProps.row.original.statusflag;
          return (
            <div className="d-flex align-items-center">
               {cellProps.row.original.c_name} 
               <span style={{marginLeft:'10%'}}>
               {statusflag > 0 && (
                <Badge color="danger" className="me-2">
                  Question Shortage
                </Badge>
              )}
               </span>
             </div>
          );
        }
      },
      {
        header: (
          <span style

             ={{ cursor: 'pointer' }}>
            Languages ▼
          </span>
        ),

        accessorKey: 'l_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style

             ={{ cursor: 'pointer' }}>
            Exam Board ▼
          </span>
        ),

        accessorKey: 'ex_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Board Category ▼
          </span>
        ),

        accessorKey: 'exct_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'u_stsid',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const c_stsid = cellProps.row.original.c_stsid;
          // const sts_name =cellProps.row.original.sts_name;

          return (
            <div className="d-flex align-items-center">
              {c_stsid == 1 ? (
                  //  <span>

                  //  <i className="mdi mdi-check-circle text-success me-2" id="activetooltip" style={{ fontSize: '15px' }} /> 
                  //  <UncontrolledTooltip placement="top" target="activetooltip">
                  //      Active
                  //  </UncontrolledTooltip>
                  //  </span>
                   <span>

                     <i className="mdi mdi-check-circle text-success me-2" id="activetooltip" style={{ fontSize: '25px' }} />
                     <UncontrolledTooltip placement="top" target="activetooltip">
                       Active
                   </UncontrolledTooltip>
                   </span>
              ) : 
              (
                <span>

                <i className="dripicons dripicons-wrong text-danger me-2" id="pendingtooltip" style={{ fontSize: '20px' }} />
                <UncontrolledTooltip placement="top" target="pendingtooltip">
                Inactive
            </UncontrolledTooltip>
            </span>
              )
            
            }
            </div>
          );
        },
      },   {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Created By ▼
        </span>
        ),
        
        accessorKey: 'createdby',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                             <span>{mk_stsid}</span>

              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );

  const handleAddClick = () => {
    navigate(
      `/add-configuration/0`
    )
  };

  //meta title
  //document.title = "Masters | Exam Blue Print";

  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.ExamBluePrint)
      console.log(page_menu_list, 'page_menu_list')
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Blue Print" /> */}
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={blueprint || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        isAddButton={canCreate}
                        buttonName="Add"
                        SearchPlaceholder="Search..."
                        pagination="pagination"
                        handleUserClick={handleAddClick}
                        // isCustomPageSize={true}

                        //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"

                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                        
                  />
                      
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>

        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
ConfigurationList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default ConfigurationList;