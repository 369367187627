import React, { useEffect, useMemo, useState } from 'react';
import { Card, CardBody, Col, Modal, ModalBody, ModalHeader, Row, Label, Input, FormFeedback, Form, CardTitle, UncontrolledTooltip, Collapse } from 'reactstrap';
import { useSelector, useDispatch } from "react-redux";

import Spinners from '../../components/Common/Spinner';
import Paginations from '../../components/Common/Pagination';
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import * as url from "../../../src/helpers/url_helper";
import * as constants from "constants/layout"
import { logoutUser } from "store/auth/login/actions";
import { getLoggedInUser, getRefreshToken } from "../../../src/helpers/fakebackend_helper";
import DeleteModal from "../../components/Common/DeleteModal";
// format number
import { PatternFormat } from "react-number-format";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const QuestionData = ({ filterData, searchData }) => {
    const navigate = useNavigate();
    const store = useSelector(state => state.ExamBoardCategory)

    const user = getLoggedInUser()
    const [modal, setModal] = useState(false);
    const [board, setBoard] = useState();
    const [isLoading, setLoading] = useState(true)
    const [isOpen, setIsOpen] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [generalsetting, setGeneralSetting] = useState("");

    // pagination
    const [questionGridData, setQuestionGridData] = useState()
    const [currentPage, setCurrentPage] = useState(1);
    const perPageData = 8;
    const indexOfLast = currentPage * perPageData;
    const [totalCount, setTotalCount] = useState(0);
    // const indexOfFirst = indexOfLast - perPageData;
    // const currentData = useMemo(() => questionGrid?.slice(indexOfFirst, indexOfLast), [questionGrid, indexOfFirst, indexOfLast])

    // useEffect(() => {
    //     setQuestionGridData(currentData);
    // }, [currentData]);

    useEffect(() => {

        var page_indexOfLast = currentPage * perPageData;
        var offset = page_indexOfLast - perPageData;
        GetQAList(offset);
    }, [filterData, currentPage, searchData]);

    useEffect(() => {
        if (store.masterlist != undefined) {
         

      
            if(store.masterlist && store.masterlist.generalSettings){
                setGeneralSetting(store.masterlist.generalSettings)
            }

           
        }








        // }, 10000);



    }, [store.masterlist])

    async function GetQAList(offset) {

        const user = getLoggedInUser()


        if (filterData.selectedSubjectName != 0) {
            var [q_sid, q_sscid] = filterData.selectedSubjectName.split('-');
        } else var [q_sid, q_sscid] = [0, 0]
        const data = {
            "user_id": Number(user.uid),
            "q_sscid": Number(q_sscid),
            "q_tid": Number(filterData.selectedTopicName),
            "q_stsid": Number(filterData.selectedStatus),
            "q_exctid": Number(filterData.selectedBoardName),
            "q_sid": Number(q_sid),
            "q_ex_paper_id":Number(filterData.selectedExamPaper),
            "q_approvetests": Number(filterData.selectedApproveStatus),
            "limit": perPageData,
            "offset": offset,
            "searchData": searchData || "" 
        }
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'qa/getQAList', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload()     
            return  
          } 
        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    GetQAList(offset) // calling same function again getting refresh token
                }
            }
        }
        if (response1 && response1.data && response1.data.body) {
            setQuestionGridData(response1.data.body.QAList)

            setTotalCount(response1.data.body.QACount)
            return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
        } else {
            return { statusFlag: 2, message: 'Something went wrong' }
        }

    }

    const onClickDelete = (qid) => {

        setBoard({ qid });
        setDeleteModal(true);
    };

    const handleDeleteOrder = async () => {
        setLoading(true);
    
        const data = {
            user_id: Number(user.uid),
            qid: Number(board.qid)
        };
    
        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };
    
        try {
            const response = await axios.post(url.BASE_URL + 'qa/deleteQA', data, config);
            if (response && response.statusFlag === 3) { 
                toast.error(response.message, { autoClose: 2000 });
                localStorage.removeItem("authUser");
                window.location.reload();
                return;
            }
    
            if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
                const refresh = await getRefreshToken();
    
                if (refresh) {
                    if (Number(refresh) === constants.invalidRefreshToken) {
                        logoutUser();
                    } else {
                        await handleDeleteOrder();
                    }
                }
            }
    
            if (response?.data?.body?.statusFlag === 1) {
                toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            } else {
                toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            // Handle error
            console.error(error);
        } finally {
            setLoading(false);
            setDeleteModal(false);
    
           
            await GetQAList(0); 
        }
    };



    const toggle = (item) => {
      
        if(!item.isOpen || item.isOpen == false)
             loadOption(item.qid,item.qt_langflag)
        else{
                var expanded_collapsed_data = questionGridData.map(e => {
            if (e.qid == item.qid) {
                e.isOpen = false
            }
            return e;
        })
        setQuestionGridData(expanded_collapsed_data)
        }

    };

    async function loadOption(qid,qa_langid) {
        // setselectedBoardName({"label": "UPSC - upsc","value": "16"})
        const user = getLoggedInUser()


        var data = {
            user_id: Number(user.uid),
            qid: Number(qid),
            qa_langid: Number(qa_langid)
        }


        const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
        const response1 = await axios.post(url.BASE_URL + 'qa/loadOption', data, config)
        if (response1 && response1.statusFlag === 3) { // user validation
            toast.error(response1.message, { autoClose: 2000 });
            localStorage.removeItem("authUser");
            window.location.reload()     
            return  
          } 

        if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
            const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
            if (refresh) {
                if (Number(refresh) === constants.invalidRefreshToken) {
                    logoutUser()
                } else {
                    await loadOption() // calling same function again getting refresh token
                }
            }
        }
        if (response1 && response1.data && response1.data.body) {    

      

            var expanded_collapsed_data = questionGridData.map(e => {
                if (e.qid == qid) {
                    e.isOpen = true
                    e['options'] = response1.data.body.Options
                }
                return e;
            })
           
            setQuestionGridData(expanded_collapsed_data)
    

        }
    }

    // validation
    const validation = useFormik({
        initialValues: {
            fullNameInput: '',
            emailInput: '',
            phoneNumberInput: '',
            uploadResume: '',
            messageInput: '',
        },
        validationSchema: Yup.object({
            fullNameInput: Yup.string().required("Please Enter Your Name"),
            emailInput: Yup.string().required("Please Enter Your Email"),
            phoneNumberInput: Yup.string().required("Please Enter Your Number"),
            uploadResume: Yup.string().required("Add Your Resume"),
            messageInput: Yup.string().required("Please Enter Your Message"),
        }),
        onSubmit: (values) => {
            setModal();
            validation.resetForm();
        }
    });

    return (
        <React.Fragment>
            <DeleteModal
                show={deleteModal}
                onDeleteClick={handleDeleteOrder}
                onCloseClick={() => setDeleteModal(false)}
            />
            {
                isLoading ? <Spinners setLoading={setLoading} />
                    :
                    <>
                        <Row>
                            {(questionGridData || []).map((item, key) => (
                                <Col xl={6} md={6} lg={6} key={key}>
                                    <Card className="cls-qn-crd">

                                        <CardBody>
                                            <Row>
                                                <Col lg="9">
                                                    <span className="card-title">{item.boardname}</span>
                                                    {/* <div></div> */}
                                                    {item.statuscode == 1 ?
                                                        <span className="badge badge-soft-success rounded-pill  ms-1 font-size-12">
                                                            {item.status}
                                                        </span> :
                                                        <span className="badge badge-soft-danger rounded-pill  ms-1 font-size-12">
                                                            {item.status}
                                                        </span>}
                                                        {item.exlid == 1 ?
                                                        <span className="badge badge-soft-success rounded-pill  ms-1 font-size-12">
                                                            {item.exl_name}
                                                        </span> :
                                                        item.exlid == 2 ?
                                                        <span className="badge badge-soft-primary rounded-pill  ms-1 font-size-12">
                                                            {item.exl_name}
                                                        </span>:
                                                        item.exlid == 3 ?
                                                        <span className="badge badge-soft-warning rounded-pill  ms-1 font-size-12">
                                                            {item.exl_name}
                                                        </span> :
                                                        item.exlid == 4 ?
                                                        <span className="badge badge-soft-danger rounded-pill  ms-1 font-size-12">
                                                        {item.exl_name}
                                                    </span> : ""

                                                        
                                                        
                                                        
                                                        }
                                                </Col>

                                                <Col lg="3">
                                                    <div className="d-flex gap-3">
                                                    {user && user.user_previleges && user.user_previleges.find(f => 
                                                        Number(f.urp_smid) === constants.SubmenuList.QA && f.urp_type === constants.menuTypes.Create) && (
                                                        <div
                                                            to="#"
                                                            className="btn btn-sm btn-soft-warning"
                                                            onClick={() =>
                                                                {
                                                                    localStorage.setItem('isClone','1')
                                                                    navigate(
                                                                        `/add-questions/${item.qid}`
                                                                      )}
                                                                }
                                                              
                                                            >
                                                        
                                                            <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                                                            <UncontrolledTooltip placement="top" target="clonetooltip">
                                                                Clone
                                                            </UncontrolledTooltip>
                                                        </div>)}
                                                        {generalsetting && generalsetting.length > 0 && user && user.user_previleges && user.user_previleges.find(f => 
                                                            Number(f.urp_smid) === constants.SubmenuList.QA && f.urp_type === constants.menuTypes.Modify || generalsetting[0].gs_qa_approval === 1 && f.urp_type === constants.menuTypes.Special) && (
                                                        <div
                                                            to="#"
                                                            className="btn btn-sm btn-soft-info"
                                                            onClick={() =>

                                                                {
                                                                    localStorage.setItem('isClone','0')
                                                                    navigate(
                                                                        `/add-questions/${item.qid}`
                                                                      )}
                                                                }
                                                              
                                                            >
                                                            <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                                            <UncontrolledTooltip placement="top" target="edittooltip">
                                                                Edit
                                                            </UncontrolledTooltip>
                                                        </div>)}
                                                        {user && user.user_previleges && user.user_previleges.find(f => 
                                                            Number(f.urp_smid) === constants.SubmenuList.QA && f.urp_type === constants.menuTypes.Remove) && (
                                                        <div
                                                            to="#"
                                                            className="btn btn-sm btn-soft-danger"
                                                            onClick={() => {
                                                                onClickDelete(item.qid);
                                                            }}>
                                                            <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                                            <UncontrolledTooltip placement="top" target="deletetooltip">
                                                                Delete
                                                            </UncontrolledTooltip>
                                                        </div>)}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <div className="favorite-icon">
                                                <Link to="#"><i className="uil uil-heart-alt fs-18"></i></Link>
                                            </div>
                                            {/* <img src={item.img} alt="" height="50" className="mb-3" /> */}

                                            <ul className="list-inline mb-0">
                                            <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-1">{item.exampaper}</p>
                                                </li>{"| "}
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-1">{item.subjectname}</p>
                                                </li>{"| "}
                                                <li className="list-inline-item">
                                                    <p className="text-muted fs-14 mb-0">{item.topicname}</p>
                                                </li>
                                                {!item.isOpen ?
                                                    <li> <a  className="cls-qn-div btn  w-100" onClick={() => { toggle(item) }} >
                                                        {item.qt_contentflag == 1 ?
                                                            <div className='ck-content' dangerouslySetInnerHTML={{ __html: item.question }} /> :
                                                            <p onClick={() => { toggle(item) }} className="text-muted fs-14 mb-1">{item.question}</p>}

                                                    </a>
                                                    </li> : null}

                                            </ul>
                                            <Collapse className="cls-collapse-qn-active" onClick={() => { toggle(item) }} isOpen={item.isOpen} id="collapseExample">
                                                <div>

                                                    {item.qt_contentflag == 1 ?
                                                        <div  className='ck-content' dangerouslySetInnerHTML={{ __html: item.question }}/> :
                                                        <p className="text-muted fs-14 mb-1">{item.question}</p>}

                                                </div>
                                                {(item.options || []).map((option, key1) => (
                                                    <Col xl={12} md={12} lg={12} key={key1}>
                                                        <div
                                                        style={{display:'flex'}}
                                                            to="#"
                                                            className={option.qa_answerflag == 1 ? "btn btn-sm btn-soft-success" : "btn btn-sm"}

                                                        > <i className={option.qa_answerflag == 1 ? "mdi mdi-checkbox-marked-circle mr-8" : "mdi mdi-checkbox-blank-circle mr-8"}></i>
                                                        

                                                            {option.qa_contentflag == 1 ?
                                                        <div  className='qnoptiondata ck-content' dangerouslySetInnerHTML={{ __html: option.qa_name }} /> :
                                                        <p className="text-muted qnoptiondata fs-14 mb-1">{option.qa_name}</p>}
                                                        </div>


                                                    </Col>
                                                ))}

                                            </Collapse>

                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                        <Paginations
                            perPageData={perPageData}
                            data={questionGridData}
                            Qnlength={totalCount}
                            currentPage={currentPage}
                            setCurrentPage={setCurrentPage}
                            isShowingPageLength={true}
                            paginationDiv="col-sm-12 col-md-7"
                            paginationClass="pagination justify-content-end pagination-rounded"
                        />
                    </>
            }

            {/* Modal */}
            {/* <Modal isOpen={modal} toggle={() => { setModal() }} id="applyJobs">
                <div className="modal-content">
                    <ModalHeader toggle={() => setModal()} id="applyJobsLabel" className="modal-header">
                        Apply For This Job
                    </ModalHeader>
                    <ModalBody>
                        <Form onSubmit={validation.handleSubmit} autoComplete='off'>
                            <Row>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="fullNameInput">Full Name</Label>
                                        <Input type="text" id="fullNameInput" name="fullNameInput" placeholder="Enter your name" value={validation.values.fullNameInput} onChange={validation.handleChange} />
                                        {
                                            validation.touched.fullNameInput && validation.errors.fullNameInput ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.fullNameInput}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="emailInput">Email</Label>
                                        <Input type="email" id="emailInput" name="emailInput" placeholder="Enter your email" value={validation.values.emailInput} onChange={validation.handleChange} />
                                        {
                                            validation.touched.emailInput && validation.errors.emailInput ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.emailInput}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={6}>
                                    <div className="mb-3">
                                        <Label htmlFor="phoneNumberInput">Phone Number</Label>
                                        <PatternFormat className="form-control" name="phoneNumberInput" placeholder="Enter your number" value={validation.values.phoneNumberInput || ""} onChange={validation.handleChange} format="###-###-####" />

                                        {
                                            validation.touched.phoneNumberInput && validation.errors.phoneNumberInput ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.phoneNumberInput}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-3">
                                        <Label htmlFor="uploadResume">Upload Resume</Label>
                                        <Input type="file" id="uploadResume" nae='uploadResume' placeholder="Upload resume" value={validation.values.uploadResume} onChange={validation.handleChange} />
                                        {
                                            validation.touched.uploadResume && validation.errors.uploadResume ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.uploadResume}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="mb-4">
                                        <Label htmlFor="messageInput">Message</Label>
                                        <textarea className="form-control" id="messageInput" name="messageInput" rows="3" placeholder="Enter your message" value={validation.values.messageInput} onChange={validation.handleChange}></textarea>
                                        {
                                            validation.touched.messageInput && validation.errors.messageInput ?
                                                <FormFeedback type="invalid" className='d-block'>{validation.errors.messageInput}</FormFeedback>
                                                : null
                                        }
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className="text-end">
                                        <button type='submit' className="btn btn-success me-1">Send Application <i className="bx bx-send align-middle"></i></button>
                                        <button className="btn btn-outline-secondary" onClick={() => setModal()}>Cancel</button>
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                </div>
            </Modal> */}
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
        </React.Fragment>
    );
}

export default QuestionData;