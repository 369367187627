const layoutTypes = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
}

const layoutWidthTypes = {
  FLUID: "fluid",
  BOXED: "boxed",
  SCROLLABLE: "scrollable",
}

const layoutModeTypes = {
  DARK: "dark",
  LIGHT: "light",
}

const topBarThemeTypes = {
  LIGHT: "light",
  DARK: "dark",
  COLORED: "colored",
}

const leftBarThemeImageTypes = {
  NONE: "none",
  IMG1: "img1",
  IMG2: "img2",
  IMG3: "img3",
  IMG4: "img4",
}

const leftSidebarTypes = {
  DEFAULT: "default",
  COMPACT: "compact",
  ICON: "icon",
}

const leftSideBarThemeTypes = {
  LIGHT: "light",
  COLORED: "colored",
  DARK: "dark",
  WINTER: "winter",
  LADYLIP: "ladylip",
  PLUMPLATE: "plumplate",
  STRONGBLISS: "strongbliss",
  GREATWHALE: "greatwhale",
}

const mainMenuList = {
 Masters: 1, 
 StudyMaterial: 2,
 Configuration: 3,
 QA: 4,
 GeneralSettings: 5
}

const SubmenuList = {
  ExamBoard: 1,
  ExamBoardCategory: 2,
  ExamPapers: 3,
  Subject: 4,
  SubjectCategory: 5,
  Topic: 6,
  UserMaster: 7,
  UserRole: 8,
  ExamBluePrint: 9,
  MockupTest: 10,
  QA: 11,
  GeneralSettings: 12,
  // onlineTest: 13,
  Dashboard: 15,
  StudyMaterial: 13,
  StudentMaster:14
}

const menuTypes = {
  View: 1,
  Create: 2,
  Modify: 3,
  Remove: 4,
  Special: 5
}

const errorMessages = {
  NEWPASSWORD_ERROR: 'Please enter password',
  CONFORMPASSWORD_ERROR: 'Please enter confirm password',
  EQUALCONFORMPASSWORD_ERROR: 'password and confirm password dose not match',
  PASSWORD_VALIDATION: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/,
  PASSWORD_VALIDATIONERROR: 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character',
  CONFIRMPASSWORD_ERROR: 'Please enter confirm password',
  EQUALCONFIRMPASSWORD_ERROR: 'password and confirm password dose not match' 
}

const regexValidation = {
  removeOtherLanguage: /[\u{0080}-\u{FFFF}]/gu
}

const textboxLength = {
  u_name: 30,
  u_loginid: 15,
  u_password: 50,
  ur_name: 20,
  ex_name: 20,
  exp_name: 100,
  test_name: 50,
  link: 200,
  alt_text: 50,
  alt_texttest: 500,
  exct_name: 20,
  s_name: 300,
  ssc_name: 300,
  t_name: 500,
  c_name: 20,
  q_etext:5000,
  q_rtext:5000,
  qa_name:200
}

const invalidRefreshToken = 498
const EditorURL = "http://localhost:3000/ckeditor.html"

export {
  layoutTypes,
  layoutModeTypes,
  layoutWidthTypes,
  topBarThemeTypes,
  leftBarThemeImageTypes,
  leftSidebarTypes,
  leftSideBarThemeTypes,
  invalidRefreshToken,
  mainMenuList,
  SubmenuList,
  menuTypes,
  errorMessages,
  textboxLength,
  regexValidation,
  EditorURL
}
