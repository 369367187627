import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "components/Common/withRouter";
import ChangePasswordModal from "../../../components/Common/changepasswordmodal";
import { ToastContainer } from "react-toastify";
// users
//import user1 from "../../../assets/images/users/avatar-1.jpg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);

  const [username, setusername] = useState("");
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  useEffect(() => {
    // Fetch the user's name from localStorage
    const authUser = JSON.parse(localStorage.getItem("authUser"));
    if (authUser && authUser.u_name) {
      // Extract the first letter of the name
      setusername(authUser.u_name.charAt(0).toUpperCase());
    }
  }, []);

  const openChangePasswordModal = () => {
    setChangePasswordModal(true);
  };

  const closeChangePasswordModal = () => {
    setChangePasswordModal(false);
  };

  return (
    <React.Fragment>
      <ChangePasswordModal
        isOpen={changePasswordModal}
        toggle={closeChangePasswordModal}
      />
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item "
          id="page-header-user-dropdown"
          tag="button"
        >
         
          {/* Display the first letter of the username as the profile image */}
          <div className="rounded-circle header-profile-user" style={{ width: '40px', height: '40px', background: '#007bff', color: '#fff', display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '20px' }}>
            {username}
          </div>
          {/* <span className="d-none d-xl-inline-block ms-2 me-1">{username}</span> */}
          {/* <i className="mdi mdi-chevron-down d-none d-xl-inline-block" /> */}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag="a" onClick={openChangePasswordModal}>
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Change Password")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link>
        </DropdownMenu>
      </Dropdown>
      <ToastContainer />
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any
};

export default withRouter(withTranslation()(ProfileMenu));
