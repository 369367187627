// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link, useLocation, useNavigate  } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";
import { getmasterlist, addNewUserrole, resetUserRoleStore, getUserRoleDetails } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as constants from "../../../constants/layout"

const Userrole = (props) => {
    const dispatch = useDispatch();
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    //  const navigate = useNavigate();
    const location = useLocation();
    const ex_store = useSelector(state => state.ExamBoardCategory)
    const store = useSelector(state => state.Userrole)
    const user = getLoggedInUser()
    const [isLoading, setLoading] = useState(false);
    const [menuList, setMenuList] = useState([])   
    const [userRoleName, setuserRoleName] = useState('');
    const [userRoleId, setUserRoleId] = useState('');
    const [selectAll, setSelectAll] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [tableData, setTableData] = useState([])
    const [mainMenu, setmainMenu] = useState('')
    const [savebtnTrigger, setSavebtnTrigger] = useState(false)
    const [selUserRole, setSelUserRole] = useState('')
    const [selUserRoleClone, setSelUserRoleClone] = useState('')
    const [isEdit, setIsEdit] = useState('')
    const [changeEvent, setChangeEvent] = useState(false)
    const [isClone, setIsClone] = useState(false)
    const [statusUserRole, setStatusUserRole] =  useState(true)
    const disableButton = () => {
        setButtonDisabled(true);
      };
    
      const enableButton = () => {
        setButtonDisabled(false);
      };
    const [columns, setColumns] = useState(
        [
            {
                header: 'S.No.',
                accessorKey: 'sno',
                width: '8%',
                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1   
            },
            // {
            //     header: 'Main Menu',
            //     accessorKey: 'Main',
            //     enableColumnFilter: false,
            //     enableSorting: true,
            // },
            {
                header: 'Sub Menu',
                accessorKey: 'm_smname',
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'View',
                accessorKey: "view",             
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Create',
                accessorKey: "create",               
                enableColumnFilter: false,
                enableSorting: true,
            },

            {
                header: 'Modify',
                accessorKey: "modify",               
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Remove',
                accessorKey: "remove",               
                enableColumnFilter: false,
                enableSorting: true,
            },
            {
                header: 'Special',
                accessorKey: "special",               
                enableColumnFilter: false,
                enableSorting: true,
            },

        ],
        []
    );
    const getmasterlistapi = () => {
        setLoading(true)
        dispatch(getmasterlist({ user_id: Number(user.uid) }));
    }
    
    useEffect(() => {
    getmasterlistapi()
    document.getElementById('headerTitle').innerHTML='User Role';
    }, [dispatch]);

    useEffect(() => {
    if (ex_store.masterlist && ex_store.masterlist.menuList && !isEdit) {
        if(!selUserRole) {      
            let arr = []
            ex_store.masterlist.menuList.forEach(m => {
                const has_menu = arr.findIndex(a => a.mid === m.mid)
                if (has_menu < 0) {
                    arr.push({...m, ...{subMenu : [{...m, ...{view: false, create: false, modify: false, remove:false, special: false}}]}})                    
                } else {
                    arr.forEach(a => {  
                    if (a.mid === m.mid) {         
                        a.subMenu.push({...m, ...{view: false, create: false, modify: false, remove:false, special: false}})
                    }
                    })
                }
            });
            setMenuList(arr)
            setTableData(arr[0].subMenu)
            setmainMenu(arr[0]) 
        }
    }
        
    }, [ex_store.masterlist])

    const handleClose = () => {
      
        // navigate("/list-userrole");
    }

    const handleuserRoleNameChange = (e) => {
        setuserRoleName(e.target.value);
        // setChangeEvent(true)
    };
    
    const handleSelectAllChange = () => {
        const allSelected = !selectAll;
        setSelectAll(allSelected);
        const updatedSelectedCheckboxes = Object.assign([], tableData)
        updatedSelectedCheckboxes.forEach(ch => {
            ch.view = allSelected
            ch.create = allSelected
            ch.modify = allSelected
            ch.remove = allSelected
            ch.special = allSelected           
        });
        setTableData(updatedSelectedCheckboxes)
        setSelectedCheckboxes(updatedSelectedCheckboxes) 
         setChangeEvent(true)
    };

    const handleCheckboxChange = (row, action) => {
       
        console.log(row, menuList, 'handleCheckboxChange')
        const updatedSelectedCheckboxes = Object.assign([], tableData)
        updatedSelectedCheckboxes.forEach(ch => {
            if(ch.m_smid === row.m_smid) {
                if(action === 'view') {
                    ch.view = !ch.view
                    if(ch.view === false) {
                        ch.create = false
                        ch.modify = false
                        ch.remove = false
                        ch.special = false
                    }
                }
                if(action === 'create') {
                    ch.create = !ch.create
                }
                if(action === 'modify') {
                    ch.modify = !ch.modify
                }
                if(action === 'remove') {
                    ch.remove = !ch.remove
                }
                if(action === 'special') {
                    ch.special = !ch.special
                }
            }
        });
        setTableData(updatedSelectedCheckboxes)
        setSelectedCheckboxes(updatedSelectedCheckboxes)    
        setChangeEvent(true)    
    };

    
    const handleMainMenuData = (menu) => {
        if(changeEvent) {
            toast.error('Please save this menu before leave', { autoClose: 2000 });
            return
        }
        setTableData(menu.subMenu)      
        setmainMenu(menu) 
        setSelectAll(false);
        if (menu && menu.mid && userRoleId) {           
            setLoading(true)
            const reqData = {                
              "user_id":  Number(user.uid),
              "urid": Number(userRoleId),
              "mid": Number(menu.mid)
              }
            dispatch(getUserRoleDetails(reqData));
          }
       
    }

   
    const saveUserRoleMenus = () => {
        if(!userRoleName || (userRoleName && userRoleName.trim() === '')) {
            toast.error('Please enter user role name', { autoClose: 2000 });
            return
        }

        if(tableData && tableData.length > 0 && mainMenu) {
          
            let fn_arr = []
            tableData.forEach(s => {
               if(s.view === true) {
                fn_arr.push({mid: s.mid, m_smid: s.m_smid, type: 1})
               }
               if(s.create === true) {
                fn_arr.push({mid: s.mid, m_smid: s.m_smid, type: 2})
                }
                if(s.modify === true) {
                    fn_arr.push({mid: s.mid, m_smid: s.m_smid, type: 3})
                }
                if(s.remove === true) {
                    fn_arr.push({mid: s.mid, m_smid: s.m_smid, type: 4})
                }
                if(s.special === true) {
                    fn_arr.push({mid: s.mid, m_smid: s.m_smid, type: 5})
                }
                
            });

            if(fn_arr.length === 0) {
                toast.error('Please select atleast one menu', { autoClose: 2000 });
                return
            }
            setLoading(true)
            setSavebtnTrigger(true)
            if (isEdit && !isClone) { //update
                const reqData = {                
                    "user_id":  Number(user.uid),
                    "process_type": "update",
                    "ur_name": userRoleName,
                    "menuList": fn_arr,
                    "urid": Number(userRoleId),
                    "main_menu_id": Number(mainMenu.mid),
                    "ur_status":statusUserRole === true ? 1 : 2
                }
                disableButton()

                dispatch(addNewUserrole(reqData))
            } else if (isEdit && isClone) { //clone save
                const reqData = {                
                    "user_id":  Number(user.uid),
                    "process_type": "save",
                    "ur_name": userRoleName,
                    "menuList": fn_arr,
                    "ur_status":statusUserRole === true ? 1 : 2
                }
                disableButton()

                dispatch(addNewUserrole(reqData))
            } else { //save
                const reqData = {                
                    "user_id":  Number(user.uid),
                    "process_type": "save",
                    "ur_name": userRoleName,
                    "menuList": fn_arr,
                    "ur_status":statusUserRole === true ? 1 : 2
                }
                disableButton()

                dispatch(addNewUserrole(reqData))
            }
            
        }
       
    }

    //After api call success stop loading
  useEffect(() => {
    enableButton()
    if (store.ur_role_statusFlag === 1) { 
        if(savebtnTrigger) { // save then refresh list
         setSavebtnTrigger(false) 
          setChangeEvent(false)
            if(store.ur_urid) {
                setUserRoleId(store.ur_urid)
                setIsEdit(true)
                setIsClone(false)
            } 
        }           
      setLoading(false)
      dispatch(resetUserRoleStore({statusFlag: 0}));
    } else if (store.ur_role_statusFlag === 2) { 
        if(savebtnTrigger) {
         setSavebtnTrigger(false)
        }    
      setLoading(false)
      dispatch(resetUserRoleStore({statusFlag: 0}));
    }
  }, [store.ur_role_statusFlag])

  useEffect(() => {
    if(location?.state) {
        if(location?.state?.urid) {
            setSelUserRole(location?.state?.urid)  
            setUserRoleId(location?.state?.urid)
        }     
        if(location?.state?.clone_urid) {
            setSelUserRoleClone(location?.state?.clone_urid)  
            setUserRoleId(location?.state?.clone_urid)
        }  
        if(location?.state?.ur_stsid === 1) {
            setStatusUserRole(true) 
        }
        if(location?.state?.ur_stsid === 2) {
            setStatusUserRole(false) 
        }
    }
  }, [location])

  useEffect(() => {
    console.log(tableData, 'tableData')
  },[tableData])

  //After api call success stop loading
  useEffect(() => {
    if (mainMenu && mainMenu.mid && location.state && (location.state.urid !== '' || location.state.clone_urid !== '') 
    && (selUserRole || selUserRoleClone)) {           
    //   setLoading(true)
     let u_urid = location.state.urid
      if(location.state.urid) {
          u_urid = location.state.urid
      }
      if(location.state.clone_urid) {
        setIsClone(true) 
        u_urid = location.state.clone_urid
      }
      const reqData = {                
        "user_id":  Number(user.uid),
        "urid": Number(u_urid),
        "mid": Number(mainMenu.mid)
        }
        setSelUserRole('')
        setSelUserRoleClone('')
      dispatch(getUserRoleDetails(reqData));
    }
  }, [mainMenu])
  const toggleStatus = () => {
    setStatusUserRole(!statusUserRole);
    // setChangeEvent(true)
};

  useEffect(() => {
    if(store.userRoleDetails && store.userRoleDetails.length >0 && ex_store?.masterlist?.menuList && ex_store?.masterlist?.menuList?.length > 0) {
        console.log(store.userRoleDetails, 'store.userRoleDetails')
        let menulist = ex_store.masterlist.menuList
        if (!isClone) {
            // setChangeEvent(true)
            setuserRoleName(store.userRoleDetails[0].ur_name)
        }
        
        store.userRoleDetails.forEach(a => {
            menulist.forEach(m => {
               if (Number(m.mid) === Number(a.mid) && Number(a.smid) === Number(m.m_smid)) {
                   if (Number(a.type) === 1) {
                    m.view = true
                   }
                   if (Number(a.type) === 2) {
                       m.create = true
                    }
                    if (Number(a.type) === 3) {
                        m.modify = true
                    }
                    if (Number(a.type) === 4) {
                        m.remove = true
                    }
                    if (Number(a.type) === 5) {
                        m.special = true
                    }
               }
           });
        });
        if (menulist && menulist.length > 0) {
            let arr = []
            menulist.forEach(m => {
                const has_menu = arr.findIndex(a => a.mid === m.mid)
                if (has_menu < 0) {
                    arr.push({...m, ...{subMenu : [m]}})                    
                } else {
                    arr.forEach(a => {  
                    if (a.mid === m.mid) {         
                        a.subMenu.push(m)
                    }
                    })
                }
            });
            
            setMenuList(arr)
            setTableData(arr.find(a => Number(a.mid) === Number(mainMenu.mid))?.subMenu)
            setmainMenu(arr.find(a => Number(a.mid) === Number(mainMenu.mid))) 
        }        
        setIsEdit(true)
                
    }
  }, [store.userRoleDetails])
  

    //meta title
    document.title = "Masters | User Role";

    return (
        <React.Fragment>            
            <div className="page-content" style={{ height: '100%' }}>
                <div className="container-fluid">
                    {/* <Breadcrumbs title="Masters" breadcrumbItem="User Role" /> */}
                    <Row>
                        {
                            isLoading ? <Spinners setLoading={setLoading} />
                                :
                                <Col xs="12">
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col xs="12" sm="12" lg="4" md="3">
                                                    <FormGroup className="mb-3 d-flex align-items-center">
                                                        <Label className="me-2 flex-shrink-0" style={{ marginTop: '10px' }}>User Role Name</Label>
                                                        <Input
                                                            type="text"
                                                            placeholder="Enter user role name"
                                                            value={userRoleName}
                                                            onChange={handleuserRoleNameChange}
                                                            maxLength={constants.textboxLength.ur_name}
                                                            autoFocus 
                                                        />
                                                    </FormGroup>
                                                </Col>
                                                {isEdit && (
                                                    
                                                        <Col sm="9">
                                                            <div className="square-switch" style={{float: 'right'}}>
                                                                <input
                                                                    type="checkbox"
                                                                    id="statusUserRole"
                                                                    className="switch switch-bool"
                                                                    checked={statusUserRole} // Reflect status state
                                                                    onClick={toggleStatus} // Toggle status when switch changes
                                                                />
                                                                <label
                                                                    htmlFor="statusUserRole"
                                                                    data-on-label="active"
                                                                    data-off-label="Inactive"
                                                                />
                                                            </div>
                                                        </Col>
                                                   )}

                                            </Row>
                                            <Row className="mb-3">
                                                <div className="d-flex flex-wrap gap-2 justify-content-start">
                                                {menuList && menuList.length && menuList.map((m, i) => {
                                                    return (
                                                        <div key = {i+"_selected"} > 
                                                        {m && tableData && tableData[0] && m.mid  === tableData[0].mid && (
                                                            <Button type="button" 
                                                        style={{ color: '#fff', backgroundColor: '#556ee6', borderColor: 'transparent' }}
                                                        // style={m.mid === tableData[0].mid ? {} : ''}
                                                        onClick={() => {
                                                            handleMainMenuData(m)
                                                        }}
                                                        className="btn btn-soft-primary waves-effect waves-light">{m.m_name}</Button>

                                                        )}

                                                        {m && tableData && tableData[0] && m.mid  !== tableData[0].mid && (
                                                            <Button type="button"
                                                            onClick={() => {
                                                                handleMainMenuData(m)
                                                            }}
                                                            className="btn btn-soft-primary waves-effect waves-light">{m.m_name}</Button>
                                                        )}
                                                        
                                                    {/* <Button type="button" onClick={() => {
                                                        handleMainMenuData('qa')
                                                    }} className="btn btn-soft-primary waves-effect waves-light">Q&A</Button>

                                                    <Button type="button" onClick={() => {
                                                        handleMainMenuData('blueprint')
                                                    }} className="btn btn-soft-primary waves-effect waves-light">Exam Blue PRint</Button> */}
                                                    </div>
                                                    )
                                                })} 
                                                </div>
                                            </Row>
                                            <Row className="mb-2">
                                                <div className="d-flex flex-wrap gap-1 align-items-center justify-content-start">
                                                    <Input
                                                        type="checkbox"
                                                        id="selectAllCheckbox"
                                                        checked={selectAll}
                                                        onClick={handleSelectAllChange}
                                                    />
                                                    <Label className="mb-0 ms-2">All</Label>
                                                </div>

                                            </Row>
                                            <TableContainer
                                                columns={columns.map((column) => {
                                                    if (column.accessorKey === "view") {
                                                        // Add a custom cell for the checkbox column
                                                        return {
                                                            ...column,
                                                            cell: (cellProps) => (
                                                                <FormGroup check className="font-size-16"  key={cellProps.row.index+'_viewform'}>
                                                                    <Label check>
                                                                        <Input
                                                                            id={cellProps.row.index+"_view_checkbox"}
                                                                            name={cellProps.row.index+"_view_checkbox"}
                                                                            type="checkbox"
                                                                            checked={cellProps?.row?.original?.view ? true : false}
                                                                            onChange={() => handleCheckboxChange(cellProps.row.original, 'view')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                            ),
                                                        };
                                                    }
                                                    if (column.accessorKey === "create") {
                                                        // Add a custom cell for the checkbox column
                                                        return {
                                                            ...column,
                                                            cell: (cellProps) => (
                                                                <FormGroup check className="font-size-16" key={cellProps.row.index+'_createform'}>
                                                                    <Label check>
                                                                        <Input
                                                                            id={cellProps.row.index+"_create_checkbox"}
                                                                            name={cellProps.row.index+"_create_checkbox"}
                                                                            type="checkbox"
                                                                            disabled={cellProps?.row?.original?.view ? false : true}
                                                                            checked={cellProps?.row?.original?.create ? true : false}
                                                                            onChange={() => handleCheckboxChange(cellProps.row.original, 'create')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                            ),
                                                        };
                                                    }
                                                    if (column.accessorKey === "modify") {
                                                        // Add a custom cell for the checkbox column
                                                        return {
                                                            ...column,
                                                            cell: (cellProps) => (
                                                                <FormGroup check className="font-size-16" key={cellProps.row.index+'_modifyform'}>
                                                                    <Label check>
                                                                        <Input
                                                                            id={cellProps.row.index+"_modify_checkbox"}
                                                                            name={cellProps.row.index+"_modify_checkbox"}
                                                                            type="checkbox"
                                                                            disabled={cellProps?.row?.original?.view ? false : true}
                                                                            checked={cellProps?.row?.original?.modify ? true : false}
                                                                            onChange={() => handleCheckboxChange(cellProps.row.original, 'modify')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                            ),
                                                        };
                                                    }
                                                    if (column.accessorKey === "remove") {
                                                        // Add a custom cell for the checkbox column
                                                        return {
                                                            ...column,
                                                            cell: (cellProps) => (
                                                                <FormGroup check className="font-size-16" key={cellProps.row.index+'_removeform'}>
                                                                    <Label check>
                                                                        <Input
                                                                            id={cellProps.row.index+"_remove_checkbox"}
                                                                            name={cellProps.row.index+"_remove_checkbox"}
                                                                            type="checkbox"
                                                                            disabled={cellProps?.row?.original?.view ? false : true}
                                                                            checked={cellProps?.row?.original?.remove ? true : false}
                                                                            onChange={() => handleCheckboxChange(cellProps.row.original, 'remove')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                            ),
                                                        };
                                                    }
                                                    if (column.accessorKey === "special") {
                                                        // Add a custom cell for the checkbox column
                                                        return {
                                                            ...column,
                                                            cell: (cellProps) => (
                                                                <FormGroup check className="font-size-16" key={cellProps.row.index+'_specialform'}>
                                                                    <Label check>
                                                                        <Input
                                                                            id={cellProps.row.index+"_special_checkbox"}
                                                                            name={cellProps.row.index+"_special_checkbox"}
                                                                            type="checkbox"
                                                                            disabled={cellProps?.row?.original?.view ? false : true}
                                                                            checked={cellProps?.row?.original?.special ? true : false}
                                                                            onChange={() => handleCheckboxChange(cellProps.row.original, 'special')}
                                                                        />
                                                                    </Label>
                                                                </FormGroup>
                                                            ),
                                                        };
                                                    }
                                                    return column;
                                                })}
                                                data={tableData || []}

                                                pageSize={tableData.length}

                                                pagination="pagination"

                                                theadClass="table-light"
                                                paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                            />
                                               
                                            <div className="text-end mb-4">
                                         
                                        <Button color="success"
                                            type="button"
                                            className="save-user"
                                            // disabled={!changeEvent}
                                            onClick={() => {
                                                saveUserRoleMenus()
                                            }}
                                        >
                                            {isEdit && !isClone ? "Update" : "Save"}
                                        </Button>
                                        <Link to={'/list-userrole'}>
                                        <button type="button" style={{marginLeft: "10px"}} 
                                             onClick={handleClose}
                                            className="btn btn-danger">Close</button>
                                        </Link>
                                      
                                    </div>
                                        </CardBody>
                                    </Card>
                                    
                                </Col>
                        }
                    </Row>
                </div>
            </div>
            <ToastContainer
            position="top-right"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
        </React.Fragment>
    );
}
Userrole.propTypes = {
    preGlobalFilteredRows: PropTypes.any,

};


export default Userrole;