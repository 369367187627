import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postLogin,
} from "../../../helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
const fireBaseBackend = getFirebaseBackend();

function* loginUser({ payload: { user, history } }) {
  try {    
      const response = yield call(postLogin, user);   
      console.log(response, 'login_response')   
      if (response && response?.data?.body?.status === 200) {
        localStorage.setItem("authUser", JSON.stringify(response?.data?.body?.UserInfo));
        yield put(loginSuccess(response?.data?.body?.UserInfo));
        toast.success(response?.data?.body?.message, { autoClose: 2000 });
        history('/Dashboard');
      } else if (response && response?.data?.body?.status === 401) {
        toast.error(response?.data?.body?.message, { autoClose: 2000 });
      } else {
        toast.error('Something went wrong', { autoClose: 2000 });
      }
    // history('/questions');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser");
    history('/');
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
