// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { getsubjectsubcategory, addsubjectsubcategory, deletesubjectsubcategory, resetsubjectsubcategory, getmasterlist } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import * as url from "../../../helpers/url_helper";
import { logoutUser } from "store/auth/login/actions";
import TopicListModal from "../../../components/Common/TopicModal"

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";


const SubjectCategoryList = () => {
  document.title = "Masters | Subject Subcategory";

  const dispatch = useDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const store = useSelector(state => state.Subjectsubcategory)
  const masterstore = useSelector(state => state.ExamBoardCategory)
  const user = getLoggedInUser()
  const [modal, setModal] = useState(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [subCatinputFocus, setSubCatInputFocus] = useState(false);
  const inputRef = useRef(null);
  // const subjectsubCatRef = useRef(null);
  const paperRef = useRef(null);
  const [submitbtn, setSubmitbtn] = useState('')
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [isEditing, setIsEditing] = useState(false);
  const [board, setBoard] = useState();
  const [isLoading, setLoading] = useState(false);
  const [masterList, setmasterList] = useState([]);
  const [allSubjectList, setAllSubjectList] = useState([]);
  const [subjectCategory, setsubjectCategory] = useState();
  const [subjectsubcategorylist, setsubjectsubcategorylist] = useState(null);
  const [selectedSubject, setselectedSubject] = useState(null);
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  const [topicModal, setTopicModal] = useState(false);
  const [topicList, setTopicList] = useState([])
  const [subjectDetails, setSubjectDetails] = useState('')
  const [selectedPaper, setselectedPaper] = useState(null);
  const [paperList, setPaperList] = useState([])

  const [subjectCategoryEdit, setsubjectCategoryEdit] = useState();
  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      categoryID: (subjectCategory && subjectCategory.categoryID) || '',
      subCategory: (subjectCategory && subjectCategory.subCategory) || '',
      selectedSubject:(subjectCategory && subjectCategory.selectedSubject) ||null,
      selectedPaper:(subjectCategory && subjectCategory.selectedPaper) ||null
},
    validationSchema: Yup.object({
      subCategory: Yup.string().required("Please Enter Subcategory Name"),
      selectedSubject: Yup.object().required("Please select Subject"),
      selectedPaper: Yup.object().required("Please select examination  paper"),
    }),
    onSubmit: (values) => {
      const [sid, sscid] = selectedSubject.value?.split('-');
      // setSubmitbtn(true)
      setLoading(true)
      if (isEditing) { //update
        const newaddsubjectsubcategory = {
          user_id: Number(user.uid),
          ssc_name: values.subCategory,
          process_type: 'update',
          ssc_sid: Number(sid),
          ssc_id: board.sscid,
          ex_paper_id:Number(selectedPaper?.value),
          ssc_sscid:Number(sscid)
        };
        // update new Exam Board
        disableButton();

        dispatch(addsubjectsubcategory(newaddsubjectsubcategory));

      } else { //add
        const newaddsubjectsubcategory = {
          user_id: Number(user.uid),
          ssc_name: values.subCategory,
          process_type: 'save',
          ssc_sid: Number(sid),
          ex_paper_id:Number(selectedPaper?.value),
          ssc_sscid:Number(sscid)
        };
        // save new Exam Board
        disableButton();

        dispatch(addsubjectsubcategory(newaddsubjectsubcategory));

      }


      // toggle();
    },
  });
  const customStyles = {
    option: (styles, state, data) => { // fixed
        return {
            ...styles,
            backgroundColor: state.isDisabled
            ? undefined
            : state.isSelected
            ? '#a6b0cf57'
            : state.isFocused
            ? '#556ee6'
            : 'white',  

            color: state.isDisabled
            ? '#ccc'
            : state.isSelected
            ? 'black'
            : state.isFocused 
            ? 'white'
            : 'black',

            cursor: state.isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !state.isDisabled
                ? state.isSelected
                    ? '#556ee6'
                    : '#556ee6'
                : undefined,
            },
            fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
        };          
    }
  };
  const [deleteModal, setDeleteModal] = useState(false);

  // After api call success stop loading
  useEffect(() => {
    enableButton()
    if (store.subjectsubcategory_statusFlag === 1) {
      if (submitbtn) { // add or update then refresh list        
        getsubcategoryList()
        // getmasterlistapi()
        if (submitbtn !== 'savecontinue') { // If it not savecontinue close popup
          setModal(false);       
        }
        setSubmitbtn('')
        validation.resetForm();
        // setmasterList([])  
        const event = {
          target: {
            name: 'selectedPaper', value: {
              label: setselectedPaper.label,
              value: setselectedPaper.value,
            }
          }
        }; 
        validation.handleChange(event);
         const subject = {
          target: {
            name: 'selectedSubject', value: {
              label: setselectedSubject.label,
              value: setselectedSubject.value,
            }
          }
        }; 
        validation.handleChange(subject);
        // subjectsubCatRef.current.focus();
        // setSubCatInputFocus(false);
        // validation.setFieldValue("subCategory",'')

        inputRef.current.focus()
        // validation.resetForm();  

      }
      if (deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        getsubcategoryList()
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resetsubjectsubcategory({ statusFlag: 0 }));
      // getmasterlistapi()
    } else if (store.subjectsubcategory_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn('')
      }
      if (deleteTrigger) {
        setDeleteTrigger(false)
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resetsubjectsubcategory({ statusFlag: 0 }));
    }
  }, [store.subjectsubcategory_statusFlag])



  const getsubcategoryList = () => {
    setLoading(true)
    dispatch(getsubjectsubcategory({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getsubcategoryList()
  }, [dispatch]);

  useEffect(() => {
    setsubjectsubcategorylist(store.subjectSubCatList);
  }, [store.subjectSubCatList]);

  const getmasterlistapi = () => {
    // setLoading(true)
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getmasterlistapi()
    document.getElementById('headerTitle').innerHTML='Subject Subcategory';
  }, [dispatch]);

  useEffect(() => {
    if (masterstore.masterlist && masterstore.masterlist.subject_subcategoryList)
    {
      setAllSubjectList(masterstore.masterlist.subject_subcategoryList)
   
      if(selectedPaper?.value)
      {
        var filtered_subjects = masterstore.masterlist.subject_subcategoryList.filter(e=>Number(e.expid) == Number(selectedPaper.value))
        setmasterList(filtered_subjects)
      }
    }      
    if (masterstore.masterlist && masterstore.masterlist.examPaperList)
      setPaperList(masterstore.masterlist.examPaperList)
    
    
    if(subjectCategoryEdit) // bind the data after call the master api
      bindEditData()
  }, [masterstore.masterlist])

  
  const handleSelectPaper = (selectedOption) => {

    var filtered_subjects = allSubjectList.filter(e=>Number(e.expid) == Number(selectedOption.value))
    setmasterList(filtered_subjects)
    setselectedPaper(selectedOption);
    setselectedSubject(null)
  };

  useEffect(() => {
    if (modal && paperRef.current && inputFocus) {
      paperRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);
  // useEffect(() => {
  //   if (modal && subjectsubCatRef.current && inputFocus) {
  //     subjectsubCatRef.current.focus();
  //     setInputFocus(false);
  //   }
  // }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  const onClickDelete = (subjectsubcategoryData) => {
    setBoard(subjectsubcategoryData);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteTrigger(true)
    setLoading(true)
    dispatch(deletesubjectsubcategory({ user_id: Number(user.uid), ssc_id: Number(board.sscid) }))
  };

  const handleSelectSubject = (selectedOption) => {
    setselectedSubject(selectedOption);
  };
  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f => 
                Number(f.urp_smid) === constants.SubmenuList.SubjectCategory && f.urp_type === constants.menuTypes.Modify) && ( 
              <div
                to="#"
                className="btn btn-sm btn-soft-info"
                onClick={() => {
                  const subjectsubcategoryData = cellProps.row.original;
                  handleEditClick(subjectsubcategoryData);
                }}
              >
                <i className="mdi mdi-pencil-outline" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </div>)}
              {user && user.user_previleges && user.user_previleges.find(f => 
                Number(f.urp_smid) === constants.SubmenuList.SubjectCategory && f.urp_type === constants.menuTypes.Remove) && ( 
              <div
                to="#"
                className="btn btn-sm btn-soft-danger"
                onClick={() => {
                  const subjectsubcategoryData = cellProps.row.original; onClickDelete(subjectsubcategoryData);
                }}>
                <i className="mdi mdi-delete-outline" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
         (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1   
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
             Exam Paper ▼ 
          </span>
      ),
       
        accessorKey: 'exampaper',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
             Subject ▼ 
          </span>
      ),
       
        accessorKey: 'subjectname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Subject Subcategory Name ▼ 
          </span>
      ),
       
        accessorKey: 'subcategoryname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          No.of Topics ▼ 
          </span>
      ),
       
        accessorKey: 'topicscount',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'txt-center', // Keep this if you want to center the content vertically
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3 justify-content-end" style={{ cursor: 'pointer' }}>
              {cellProps?.row?.original?.topicscount && Number(cellProps?.row?.original?.topicscount) !== 0 ? (
                <div style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={() => {
                  const subjectData = cellProps.row.original;
                  getTopicList(subjectData);
                }}>
                  {cellProps?.row?.original?.topicscount}
                </div>
              ) : (
                <div>{cellProps?.row?.original?.topicscount}</div>
              )}
            </div>
          );
        }
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
         No.of Questions ▼ 
          </span>
      ),
        
        accessorKey: 'questioncount',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'txt-center'
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
         Created By ▼ 
          </span>
      ),
       
        accessorKey: 'createdat',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                              <span>{mk_stsid}</span>

              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );

  const handleAddClick = () => {
    enableButton()
  //  getmasterlistapi()
    setsubjectCategoryEdit(null)
    validation.resetForm();
    setIsEditing(false)
    setselectedSubject(null)
    setselectedPaper(null)
    setBoard('')
    toggle();
  };

  const handleEditClick =  (subjectsubcategoryData) => {
    getmasterlistapi() // Call the master api
    setsubjectCategoryEdit(subjectsubcategoryData) // bind the data for accessing after the api call
    enableButton()

  };
  const bindEditData = () =>
  {
    var filtered_subjects = allSubjectList.filter(e=>Number(e.expid) == Number(subjectCategoryEdit?.expid))
    setmasterList(filtered_subjects)
    validation.resetForm();
    setBoard(subjectCategoryEdit);
    toggle();
    setIsEditing(true);

    // Update this part to set the selected Subject and Subject Subcategory Name
    validation.setValues({
      subCategory: subjectCategoryEdit?.subcategoryname,
      selectedPaper:{
        label:subjectCategoryEdit?.exampaper,
        value:subjectCategoryEdit?.expid

      },
      selectedSubject:{
        label: subjectCategoryEdit?.subcat,
        value: subjectCategoryEdit?.sid+'-'+subjectCategoryEdit?.ssc_sscid,
      },
     
    });
    setselectedPaper({
      label:subjectCategoryEdit?.exampaper,
      value:subjectCategoryEdit?.expid

    })

    setselectedSubject({
      label: subjectCategoryEdit?.subcat,
      value: subjectCategoryEdit?.sid+'-'+subjectCategoryEdit?.ssc_sscid,
    });
  
    // Manually trigger the onChange event for the Select component
    // const event = { 
    //   target: { name: 'selectedSubject', value: {
    //   label: subjectsubcategoryData.subcat,
    //   value: subjectsubcategoryData.sid+'-'+subjectsubcategoryData.ssc_sscid,
    // } } };
    // validation.handleChange(event);

  }

  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  //meta title
  // document.title = "Masters | Subject Subcategory";


  const toggleTopic = () => {
    setTopicModal(!topicModal);
  };
  
  async function getTopicList(data) {
    // setLoading(true)
    var req_data = {
        user_id: Number(user.uid),
        ssc_id: Number(data.sscid)
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'master/getSubcategoryTopics', req_data, config)
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
        const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
        if (refresh) {
            if (Number(refresh) === constants.invalidRefreshToken) {
                logoutUser()
            } else {
                await getTopicList() // calling same function again getting refresh token
            }
        }
    }
    if (response1 && response1.data && response1?.data?.body?.SubCategoryTopics) {
      setTopicList(response1?.data?.body?.SubCategoryTopics)
      setSubjectDetails(data)
      toggleTopic();   
    } else {
      setTopicList([])
      setSubjectDetails('')
    }
    // setLoading(false)
   }


   //USer role credentials
   useEffect(() => {
    if(user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.SubjectCategory)
      if(page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid  ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid  ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
       <TopicListModal
        show={topicModal}
        topicList={topicList}
        subjectDetails={subjectDetails}
        from={'subcategory'}
        onCloseClick={() => setTopicModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Subject Subcategory" /> */}
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={subjectsubcategorylist || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        isAddButton={canCreate}
                        buttonName="Add"
                        SearchPlaceholder="Search..."
                        pagination="pagination"
                        handleUserClick={handleAddClick}
                        // isCustomPageSize={true}
                        //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"

                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEditing ? "Edit Subject Subcategory" : "Add Subject Subcategory"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                  <div className="mb-3">
                      <Label>Exam Paper<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedPaper}
                        onChange={(selectedOption) => {
                          handleSelectPaper(selectedOption);
                          validation.setFieldValue("selectedPaper",selectedOption)
                        }}
                        options={paperList.map((paper) => ({
                          label: paper.exp_name,
                          value: paper.expid,
                        }))}
                        ref={paperRef} 
                        validate={{
                          required: { value: true },
                      }}

                        className={`select2-selection ${validation.touched.selectedPaper && validation.errors.selectedPaper ? 'is-invalid' : ''}`}
                                                            
                       
                        invalid={
                          validation.touched.selectedPaper && validation.errors.selectedPaper ? true : false
                        }
                      />
                       {validation.touched.selectedPaper && validation.errors.selectedPaper ? (
                        <FormFeedback type="invalid">{validation.errors.selectedPaper}</FormFeedback>
                      ) : null}
                    </div>

                     <div className="mb-3">
                      <Label>Subject<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedSubject}
                        onChange={(selectedOption) => {
                          handleSelectSubject(selectedOption);
                          validation.setFieldValue("selectedSubject",selectedOption)
                        }}
                        options={masterList.map((board) => ({
                          label: board.s_name,
                          value: board.sid+"-"+board.sscid,
                        }))}
                        styles={customStyles}
                        validate={{
                          required: { value: true },
                      }}

                        className={`select2-selection ${validation.touched.selectedSubject && validation.errors.selectedSubject ? 'is-invalid' : ''}`}
                                                            
                       
                        invalid={
                          validation.touched.selectedSubject && validation.errors.selectedSubject ? true : false
                        }
                      />
                       {validation.touched.selectedSubject && validation.errors.selectedSubject ? (
                        <FormFeedback type="invalid">{validation.errors.selectedSubject}</FormFeedback>
                      ) : null}
                    </div>


                    <div className="mb-3">
                      <Label>Subject Subcategory Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                        // ref={subjectsubCatRef} 
                        name="subCategory"
                        type="text"
                        maxLength={constants.textboxLength.ssc_name}
                        placeholder="Subject Subcategory"
                        validate={{
                          required: { value: true },
                        }}
                        innerRef={inputRef}
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.subCategory || ""}
                        invalid={
                          validation.touched.subCategory && validation.errors.subCategory ? true : false
                        }
                      />
                      {validation.touched.subCategory && validation.errors.subCategory ? (
                        <FormFeedback type="invalid">{validation.errors.subCategory}</FormFeedback>
                      ) : null}
                    </div>



                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success"
                        type="submit"
                        className="save-user"
                        disabled={isButtonDisabled}
                        onClick={() => {
                          setSubmitbtn('save')
                        }}
                      >
                        {!!isEditing ? "Update" : "Save"}
                      </Button>
                        {!isEditing && (
                          <Button color="primary" type="submit" className="save-and-continue-user" 
                          disabled={isButtonDisabled}
                          onClick={() => {
                            setSubmitbtn('savecontinue')
                          }}
                          style={{ marginLeft: '10px' }}>
                            Save and Continue
                          </Button>
                        )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
SubjectCategoryList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default SubjectCategoryList;