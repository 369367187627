import React, { useMemo, useState, useEffect } from "react";
import { Card, Button, CardBody, Col, Container, UncontrolledTooltip, Form, FormGroup, Input, InputGroup, Label, Row, FormFeedback, CardFooter } from "reactstrap";
import { useFormik } from "formik";
import { SketchPicker } from "react-color";
import { ColorPicker } from "@vtaits/react-color-picker";
//import "../../../../node_modules/@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import Select from "react-select";
import * as Yup from "yup";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { Link, useParams, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { getLoggedInUser } from "../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import TableContainer from '../../components/Common/TableContainer';
import * as constants from "../../constants/layout"
import { editloadGeneralSettings, addgeneralsettings } from "../../../src/store/generalsettings/actions";
import DeleteModal from "../../components/Common/DeleteModal";
import './generalsettings.scss'


const GeneralSettings = () => {
    document.title = "General Settings";
    const store = useSelector(state => state.GeneralSettings)
    const dispatch = useDispatch();
    const user = getLoggedInUser()
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [submitbtn, setSubmitbtn] = useState(false);
    const [initialSubmitState, setInitialSubmitState] = useState(false);
    const [quickLinks, setC_quicklinks] = useState([]);
    const [videoLinks, setC_videoLinks] = useState([]);
    const [dataTable, setDataTable] = useState([]);
    const [dataTable1, setDataTable1] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [editingRow, setEditingRow] = useState(null);
    const [editingVideoRow, setEditingVideoRow] = useState(null);
    const [negativeMark, setNegativeMark] = useState(false);
    const [linkstatus, setLinkStatus] = useState(false);
    const [videolinkstatus, setVideoLinkStatus] = useState(false);
    const [status, setstatus] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [board, setBoard] = useState(null);
    const [videos, setVideos] = useState(null);
    const [qaToggleStatus, setqaToggle] = useState(null);
    const [stToggleStatus, setstToggleStatus] = useState(null);

    const handleEditQuciklink = (row) => {
        setIsEditing(true);
        setEditingRow(row);
        validation.setFieldValue("gsql_link", row.gsql_link);
        validation.setFieldValue("gsql_alttext", row.gsql_alttext);
        if (row.gsql_stsid == 2) setLinkStatus(false)
        else setLinkStatus(true)

        // validation.setFieldValue("negativeMark1", row.gsql_link === 1);
        // setLinkStatus(row?.gsql_link
        // == 1 ? true: false)
    };
    const handleEditvideoRow = (row) => {
        setIsEditing1(true);
        setEditingVideoRow(row);
        validation.setFieldValue("gsv_link", row.gsv_link);
        validation.setFieldValue("gsv_text", row.gsv_text);
        if (row.gsv_stsid == 2) setVideoLinkStatus(false)
        else setVideoLinkStatus(true)

    };

    const geteditloadapi = () => {
        setLoading(true)
        dispatch(editloadGeneralSettings({ user_id: Number(user.uid) }));
    }

    useEffect(() => {
        document.getElementById('headerTitle').innerHTML = 'General Settings';
        geteditloadapi()
    }, [dispatch]);

    useEffect(() => {
    }, [store]);

    useEffect(() => {
        if (store && store.generalsettings_statusFlag && store.generalsettings_statusFlag === 1) {
            if (store && store.generalSettingsData && store.generalSettingsData.generalSettingsData && store.generalSettingsData.generalSettingsData.generalSettingsData && store.generalSettingsData.generalSettingsData.generalSettingsData.length > 0) {
                const editData = store.generalSettingsData.generalSettingsData.generalSettingsData[0];
         
                var qatoggle = editData?.qa_toggle_status
                var studyapproval = editData?.st_toggle_status
             
                const subjectsData = store.generalSettingsData.generalSettingsQuicklineData
                    .map((subject, index) => ({
                        sno: index + 1,
                        gsql_link: subject.gsql_link,
                        gsql_alttext: subject.gsql_alttext,
                        gsql_stsid: subject.gsql_stsid == true ? 1 : 2,

                        
                        
                    }));


                const subjectsData1 = store.generalSettingsData.generalSettingsvideoData
                    .map((subject, index) => ({
                        sno: index + 1,
                        gsv_link: subject.gsv_link,
                        gsv_text: subject.gsv_text,
                        gsv_stsid: subject.gsv_stsid == true ? 1 : 2
                    }));

                if (editData.gs_qa_approval == 2) setNegativeMark(false)
                else setNegativeMark(true)


                if (editData.gs_studymaterial_approval == 2) setstatus(false)
                else setstatus(true)
             //   if(editData?.qa_toggle_status){
                    setqaToggle(qatoggle)

             //   }
                // if(editData?.st_toggle_status){

                    setstToggleStatus(studyapproval)
                // }
                // if(subjectsData)

                // Set subjects data
                setDataTable(subjectsData);
                setDataTable1(subjectsData1);
                // Set quickLinks array
                setC_quicklinks(subjectsData);
                setC_videoLinks(subjectsData1)


            }

        }

    }, [store.generalSettingsData]);




    const [columns, setColumns] = useState(
        () => [
            {
                header: 'Actions',

                cell: (cellProps) => {
                    return (
                        <div className="d-flex gap-3">
                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Modify) && (
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => handleEditQuciklink(cellProps.row.original)}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>

                                )}

                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Remove) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        onClick={() => {
                                            onClickDelete(cellProps.row.original)
                                            // handleDeleteRow(cellProps.row.original);
                                        }}
                                    >
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',

                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Link ▼
                    </span>
                ),

                accessorKey: 'gsql_link',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div style={{width:"100%",  "text-wrap": 'balance' }}>
                            {cellProps.row.original.gsql_link}
                        </div>
                    );
                }
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Alternative Text ▼
                    </span>
                ),

                accessorKey: 'gsql_alttext',

                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div style={{width:"100%",  "text-wrap": 'balance' }}>
                            {cellProps.row.original.gsql_alttext}
                        </div>
                    );
                }
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Status ▼
                    </span>
                ),

                accessorKey: 'gsql_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const status1 = cellProps.row.original.gsql_stsid;
                    return (
                      <div className="d-flex align-items-center">
                        {status1 == 1 ? (
                          <span>Active</span>
                        ) : (
                        <span>Inactive</span>
                        )}
                      </div>
                    );
                  },
              
            }

        ],
        []
    );

    const handleClearData = () => {
        validation.setFieldValue("gsql_link", "");
        validation.setFieldValue("gsql_alttext", "");
        validation.setFieldValue("gsv_link", "");
        validation.setFieldValue("gsv_text", "");
        setIsEditing(false);
        setIsEditing1(false);
        setLinkStatus(false)
        setVideoLinkStatus(false)
        setEditingRow('');
    }

    const onClickDelete = (row) => {
        setBoard(row);
        setDeleteModal(true);
      };
    const onClickDelete1 = (row) => {
        setVideos(row);
        setDeleteModal(true);
      };
    const handleAddOrUpdateRow = () => {
        // Validate input values before adding to the table
        if (validation && validation.values &&
            validation.values.gsql_link &&
            validation.values.gsql_alttext) {

            const newRow = {
                sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                gsql_alttext: validation && validation.values && validation.values.gsql_alttext,
                gsql_link: validation && validation.values && validation.values.gsql_link,
                gsql_stsid : isEditing && linkstatus == true ? 1 : isEditing && linkstatus == false ? 2 : 1
            };

            var duplicateArray = []
            if (!isEditing) {
                duplicateArray = dataTable.filter(e => e.gsql_alttext == validation.values.gsql_alttext)
            } else {
                duplicateArray = dataTable.filter(e => e.sno != editingRow.sno && e.gsql_alttext == validation.values.gsql_alttext)
            }

            if (duplicateArray.length > 0) {
                toast.error("Alternative Text is already added.");
                return false
            }


            if (isEditing) {

                // Update the row in the data table
                setDataTable((prev) =>
                    prev.map((row) => (row.sno === editingRow.sno ? { ...row, ...newRow } : row))
                );
                var editRow = {
                    gsql_link: validation.values.gsql_link,
                    gsql_alttext: validation.values.gsql_alttext,
                    gsql_stsid: linkstatus == true ? 1 : 2
                }
                setC_quicklinks((prev) =>

                    prev.map((row) => (row.sno === editingRow.sno ? { ...row, ...editRow } : row))
                )
                setIsEditing(false);
                setEditingRow(null);
            } else {
                // Add a new row to the data table
                setDataTable([...dataTable, newRow]);

                // Push the new row into the quickLinks array
                setC_quicklinks((prev) => [
                    ...prev,
                    {
                        sno: isEditing ? editingRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                        gsql_link: validation.values.gsql_link,
                        gsql_alttext: validation.values.gsql_alttext,
                        gsql_stsid : isEditing && linkstatus == true ? 1 : isEditing && linkstatus == false ? 2 : 1

                    },
                ]);

            }

            // Clear the form fields after adding or updating a row
            validation.setFieldValue("gsql_link", "");
            validation.setFieldValue("gsql_alttext", "");
            setLinkStatus(false)

            // Display success toast
            toast.success("Row successfully added");
        } else {
            // Display an error toast 
            toast.error("Please fill all the required fields");
        }

    };
    const handleAddOrUpdateRow1 = () => {
        // Validate input values before adding to the table
        if (validation && validation.values &&
            validation.values.gsv_link &&
            validation.values.gsv_text) {


            const newRow = {
                sno: isEditing1 ? editingVideoRow.sno : dataTable.length > 0 ? dataTable[dataTable.length - 1]?.sno + 1 : 1,
                gsv_text: validation && validation.values && validation.values.gsv_text,
                gsv_link: validation && validation.values && validation.values.gsv_link,
                gsv_stsid : isEditing1 && videolinkstatus == true ? 1 : isEditing1 && videolinkstatus == false ? 2 : 1
            };

            var duplicateArray = []
            if (!isEditing1) {
                duplicateArray = dataTable.filter(e => e.gsv_text == validation.values.gsv_text)
            } else {
                duplicateArray = dataTable.filter(e => e.sno != editingVideoRow.sno && e.gsv_text == validation.values.gsv_text)
            }

            if (duplicateArray.length > 0) {
                toast.error("Alternative Text is already added.");
                return false
            }


            if (isEditing1) {

                // Update the row in the data table
                setDataTable1((prev) =>
                    prev.map((row) => (row.sno === editingVideoRow.sno ? { ...row, ...newRow } : row))
                );
                var editRow = {
                    gsv_link: validation.values.gsv_link,
                    gsv_text: validation.values.gsv_text,
                    gsv_stsid: videolinkstatus == true ? 1 : 2

                }

            
                setC_videoLinks((prev) =>

                    prev.map((row) => (row.sno === editingVideoRow.sno ? { ...row, ...editRow } : row))
                )
                setIsEditing1(false);
                setEditingRow(null);
            } else {
                // Add a new row to the data table
                setDataTable1([...dataTable1, newRow]);

                // Push the new row into the quickLinks array
                setC_videoLinks((prev) => [
                    ...prev,
                    {
                        sno: isEditing1 ? editingRow.sno : dataTable1.length > 0 ? dataTable1[dataTable1.length - 1]?.sno + 1 : 1,
                        gsv_link: validation.values.gsv_link,
                        gsv_text: validation.values.gsv_text,
                        gsv_stsid :  isEditing1 && videolinkstatus == true ? 1 : isEditing1 && videolinkstatus == false ? 2 : 1


                    },
                ]);

            }

            // Clear the form fields after adding or updating a row
            validation.setFieldValue("gsv_link", "");
            validation.setFieldValue("gsv_text", "");
            setVideoLinkStatus(false)

            // Display success toast
            toast.success("Row successfully added");
        } else {
            // Display an error toast 
            toast.error("Please fill all the required fields");
        }

    };
    // const handleAddOrUpdateRow1 = () => {
    //     // Validate input values before adding to the table
    //     if (validation && validation.values &&
    //         validation.values.gsv_link &&
    //         validation.values.gsv_text) {

    //         const newRow = {
    //             sno: isEditing1 ? editingVideoRow.sno : dataTable1.length > 0 ? dataTable1[dataTable1.length - 1]?.sno + 1 : 1,
    //             gsv_text: validation && validation.values && validation.values.gsv_text,
    //             gsv_link: validation && validation.values && validation.values.gsv_link,
    //             gsv_stsid: videolinkstatus == true ? 1 : 2

    //         };
    //         console.log('neeww', newRow)

    //         var duplicateArray = []
    //         if (!isEditing1) {
    //             console.log('data', dataTable)
    //             duplicateArray = dataTable1.filter(e => e.gsv_text == validation.values.gsv_text)
    //         } else {
                
    //             duplicateArray = dataTable1.filter(e => e.sno != editingRow.sno && e.gsv_text == validation.values.gsv_text)
    //             console.log(duplicateArray , '--')
    //         }
    //         console.log('dup', duplicateArray)

    //         if (duplicateArray.length > 0) {
    //             toast.error("Alternative Text is already added.");
    //             return false
    //         }


    //         if (isEditing1) {

    //             // Update the row in the data table
    //             setDataTable1((prev) =>
    //                 prev.map((row) => (row.sno === editingVideoRow.sno ? { ...row, ...newRow } : row))
    //             );
    //             var editRow = {
    //                 gsv_link: validation.values.gsv_link,
    //                 gsv_text: validation.values.gsv_text,
    //                 gsv_stsid: videolinkstatus == true ? 1 : 2

    //             }
    //             setC_videoLinks((prev) =>

    //                 prev.map((row) => (row.sno === editingVideoRow.sno ? { ...row, ...editRow } : row))
    //             )
    //             setIsEditing1(false);
    //             setEditingVideoRow(null);
    //         } else {
    //             // Add a new row to the data table
    //             setDataTable1([...dataTable1, newRow]);

    //             // Push the new row into the quickLinks array
    //             setC_videoLinks((prev) => [
    //                 ...prev,
    //                 {
    //                     sno: isEditing1 ? editingVideoRow.sno : dataTable1.length > 0 ? dataTable1[dataTable1.length - 1]?.sno + 1 : 1,
    //                     gsv_link: validation.values.gsv_link,
    //                     gsv_text: validation.values.gsv_text,
    //                     gsv_stsid :  videolinkstatus == true ? 1 : 2


    //                 },
    //             ]);

    //         }

    //         // Clear the form fields after adding or updating a row

    //         validation.setFieldValue("gsv_link", "");
    //         validation.setFieldValue("gsv_text", "");
    //         setVideoLinkStatus(false)

    //         // Display success toast
    //         toast.success("Row successfully added");
    //     } else {
    //         // Display an error toast 
    //         toast.error("Please fill all the required fields");
    //     }

    // };
    const [columns1, setColumns1] = useState(
        () => [
            {
                header: 'Actions',

                cell: (cellProps) => {
                    return (

                        <div className="d-flex gap-3">
                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Modify) && (
                                    <Link
                                        to="#"
                                        className="btn btn-sm btn-soft-info"
                                        onClick={() => handleEditvideoRow(cellProps.row.original)}
                                    >
                                        <i className="mdi mdi-pencil-outline" id="edittooltip" />
                                        <UncontrolledTooltip placement="top" target="edittooltip">
                                            Edit
                                        </UncontrolledTooltip>
                                    </Link>
                                )}
                            {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Remove) && (
                                    <div
                                        to="#"
                                        className="btn btn-sm btn-soft-danger"
                                        // onClick={() => {
                                        //     handleDeleteRow1(cellProps.row.original);
                                        // }}
                                        onClick={() => {
                                            onClickDelete1(cellProps.row.original)
                                            // handleDeleteRow(cellProps.row.original);
                                        }}
                                    >
                                        <i className="mdi mdi-delete-outline" id="deletetooltip" />
                                        <UncontrolledTooltip placement="top" target="deletetooltip">
                                            Delete
                                        </UncontrolledTooltip>
                                    </div>
                                )}
                        </div>
                    );
                }
            },
            {
                header: 'S.No.',
                accessorKey: 'sno',

                enableColumnFilter: false,
                enableSorting: true,
                className: 'pl-25',
                cell: ({ row, table }) =>
                    (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Link ▼
                    </span>
                ),

                accessorKey: 'gsv_link',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div style={{width:"100%",  "text-wrap": 'balance' }}>
                            {cellProps.row.original.gsv_link}
                        </div>
                    );
                }
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Alternative Text ▼
                    </span>
                ),

                accessorKey: 'gsv_text',

                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    return (
                        <div style={{width:"100%",  "text-wrap": 'balance' }}>
                            {cellProps.row.original.gsv_text}
                        </div>
                    );
                }
            },
            {
                header: (
                    <span style={{ cursor: 'pointer' }}>
                        Status ▼
                    </span>
                ),

                accessorKey: 'gsv_stsid',
                enableColumnFilter: false,
                enableSorting: true,
                cell: (cellProps) => {
                    const status = cellProps.row.original.gsv_stsid;
          
                    return (
                      <div className="d-flex align-items-center">
                        {status == 1 ? (
                          <span>Active</span>
                        ) : (
                        <span>Inactive</span>
                        )}
                      </div>
                    );
                  },
            }



        ],
        []
    );

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {

        },
        validationSchema: Yup.object({

        }),
        onSubmit: (values) => {
            const newGeneralSettings = {
                user_id: Number(user.uid),
                gs_qa_approval: negativeMark ? 1 : 2,
                gs_studymaterial_approval: status ? 1 : 2,
                gs_quicklinks: quickLinks,
                gs_videos: videoLinks
            };
            // Dispatch the action
            dispatch(addgeneralsettings(newGeneralSettings))
            setSubmitbtn(true);
            setLoading(true);
        },
    });

    const handleDeleteRow = () => {
        try {
            setDataTable(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== board.sno);
                // Return the updated data
                return updatedData;
            });
            setC_quicklinks(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== board.sno);
                // Return the updated data
                return updatedData;
            });
            setDeleteModal(false)
            setBoard(null)

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const handleDeleteRow1 = () => {
        try {

            setDataTable1(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== videos.sno);
                // Return the updated data
                return updatedData;
            });
            setC_videoLinks(prevData => {
                // Filter out the row to be deleted based on some identifier (e.g., id)
                const updatedData = prevData.filter(item => item.sno !== videos.sno);
                // Return the updated data
                return updatedData;
            });
            setDeleteModal(false)
            setVideos(null)

        } catch (error) {
            console.error("Error deleting row:", error);
        }
    };

    const handleDeleteOrder = () => {
        // setDeleteTrigger(true);
        if(board){

            handleDeleteRow()
        }else if(videos){
            handleDeleteRow1()

        }
        setLoading(true);
        // dispatch(deleteExamBoard({ user_id: Number(user.uid), ex_board_id: Number(board.exid) }))
      };
    
    const customStyles = {
        option: (styles, state, data) => { // fixed
            return {
                ...styles,
                backgroundColor: state.isDisabled
                    ? undefined
                    : state.isSelected
                        ? '#a6b0cf57'
                        : state.isFocused
                            ? '#556ee6'
                            : 'white',

                color: state.isDisabled
                    ? '#ccc'
                    : state.isSelected
                        ? 'black'
                        : state.isFocused
                            ? 'white'
                            : 'black',

                cursor: state.isDisabled ? 'not-allowed' : 'default',

                ':active': {
                    ...styles[':active'],
                    backgroundColor: !state.isDisabled
                        ? state.isSelected
                            ? '#556ee6'
                            : '#556ee6'
                        : undefined,
                },
                fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
            };
        }
    };
  
    return (
        <React.Fragment>
            <ToastContainer />
            <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
            <div className="page-content">
                <Container fluid={true}>

                    <Row>
                        <Col lg="12">
                            <Form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    validation.handleSubmit();
                                }}
                            >
                                <Card className="crd-min-ht">
                                    <CardBody>
                                        <Row>
                                            <Col lg="9">
                                                <Card style={{ backgroundColor: 'rgb(244 245 249)', padding: 16 }}>
                                                    <CardBody>
                                                        <h3>Quick Links</h3>
                                                        <Row>
                                                            <Col lg="6">
                                                                <Label>Link<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="gsql_link"
                                                                    type="text"
                                                                    placeholder="Enter a link"
                                                                    maxLength={constants.textboxLength.link}

                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.gsql_link}
                                                                    invalid={validation.touched.gsql_link && validation.errors.gsql_link ? true : false}
                                                                    autoFocus
                                                                />
                                                                {validation.touched.gsql_link && validation.errors.gsql_link ? (
                                                                    <FormFeedback type="invalid">{validation.errors.gsql_link}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col lg="2">
                                                                <Label>Alternative Text<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="gsql_alttext"
                                                                    type="text"
                                                                    maxLength={constants.textboxLength.alt_texttest}
                                                                    placeholder="Enter a Alternative Text"
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.gsql_alttext}
                                                                    invalid={validation.touched.gsql_alttext && validation.errors.gsql_alttext ? true : false}
                                                                />
                                                                {validation.touched.gsql_alttext && validation.errors.gsql_alttext ? (
                                                                    <FormFeedback type="invalid">{validation.errors.gsql_alttext}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            {isEditing ?
                                                            
                                                            <Col lg="2">
                                                            <div className="mb-3">
                                                            <Label>Status</Label>
                                                            <div className="square-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    id="negativeMark1"
                                                                    className="switch switch-info"
                                                                    checked={linkstatus}
                                                                    onClick={() => setLinkStatus(!linkstatus)}
                                                                />
                                                                <label htmlFor="negativeMark1" data-on-label="Yes" data-off-label="No" />
                                                            </div>
                                                        </div>


                                                            </Col> : ""
                                                            }
                                                            <Col lg="2" style={{ padding: '0', marginTop: '3%' }}>
                                                                {user && user.user_previleges && user.user_previleges.find(f =>
                                                                    Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Create) && (
                                                                        <Button
                                                                            color="success"
                                                                            type="button"
                                                                            className="btn btn-success btn-rounded waves-effect waves-light addOrder-modal"
                                                                            style={{ fontSize: '12px', fontWeight: 'bold', marginRight: '5px!important' }}
                                                                            onClick={handleAddOrUpdateRow}

                                                                        >

                                                                            {isEditing ? '✓' : '+'}
                                                                        </Button>
                                                                    )}
                                                                {isEditing && (

                                                                    <Button
                                                                        color="danger"
                                                                        type="button"
                                                                        className="btn btn-danger btn-rounded waves-effect waves-light"
                                                                        style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: "5px" }}
                                                                        onClick={handleClearData}

                                                                    >
                                                                        X
                                                                    </Button>)}

                                                            </Col>
                                                         
                                                        </Row>
                                                        <Row>
                                                            {dataTable.length > 0 ?
                                                                <TableContainer
                                                                    columns={columns}
                                                                    data={dataTable || []}
                                                                    pageSize={5}
                                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                                    isPagination={true}
                                                                    pagination="pagination"
                                                                    buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                                                                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                                    theadClass="table-light"
                                                                /> : ''}

                                                        </Row>
                                                    </CardBody>
                                                </Card>

                                                <Card  style={{ backgroundColor: 'rgb(244 245 249)', padding: 16 }}>
                                                    <CardBody>
                                                        <Row>
                                                            <h3>Upload Motivational Speech/Videos </h3>
                                                            <Col lg="6">
                                                                <Label>Link<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="gsv_link"
                                                                    type="text"
                                                                    placeholder="Enter a link"
                                                                    maxLength={constants.textboxLength.link}
                                                                    validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.gsv_link}
                                                                    invalid={validation.touched.gsv_link && validation.errors.gsv_link ? true : false}
                                                                />
                                                                {validation.touched.gsv_link && validation.errors.gsv_link ? (
                                                                    <FormFeedback type="invalid">{validation.errors.gsv_link}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            <Col lg="2">
                                                                <Label>Alternative Text<span style={{ color: 'red' }}>*</span></Label>
                                                                <Input
                                                                    name="gsv_text"
                                                                    type="text"
                                                                    placeholder="Enter a Alternative Text"
                                                                    maxLength={constants.textboxLength.alt_texttest} validate={{
                                                                        required: { value: true },
                                                                    }}
                                                                    onChange={validation.handleChange}
                                                                    onBlur={validation.handleBlur}
                                                                    value={validation.values.gsv_text}
                                                                    invalid={validation.touched.gsv_text && validation.errors.gsv_text ? true : false}
                                                                />
                                                                {validation.touched.gsv_text && validation.errors.gsv_text ? (
                                                                    <FormFeedback type="invalid">{validation.errors.gsv_text}</FormFeedback>
                                                                ) : null}
                                                            </Col>
                                                            {isEditing1 ? 
                                                            <Col lg="2">
                                                            <div className="mb-3">
                                                            <Label>Status</Label>
                                                            <div className="square-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    id="negativeMark2"
                                                                    className="switch switch-info"
                                                                    checked={videolinkstatus}
                                                                    onClick={() => setVideoLinkStatus(!videolinkstatus)}
                                                                />
                                                                <label htmlFor="negativeMark2" data-on-label="Yes" data-off-label="No" />
                                                            </div>
                                                        </div>


                                                            </Col> : ""
                                                            }
                                                            <Col lg="2" style={{ padding: '0', marginTop: '3%' }}>
                                                                {user && user.user_previleges && user.user_previleges.find(f =>
                                                                    Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Create) && (
                                                                        <Button
                                                                            color="success"
                                                                            type="button"
                                                                            className="btn btn-success btn-rounded waves-effect waves-light addOrder-modal"
                                                                            style={{ fontSize: '12px', fontWeight: 'bold', marginRight: '5px!important' }}
                                                                            onClick={handleAddOrUpdateRow1}

                                                                        >
                                                                            {isEditing1 ? '✓' : '+'}
                                                                        </Button>
                                                                    )}

                                                                {isEditing1 && (
                                                                    <Button
                                                                        color="danger"
                                                                        type="button"
                                                                        className="btn btn-danger btn-rounded waves-effect waves-light"
                                                                        style={{ fontSize: '12px', fontWeight: 'bold', marginLeft: "5px" }}
                                                                        onClick={handleClearData}

                                                                    >
                                                                        X
                                                                    </Button>)}
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            {dataTable1 && dataTable1.length > 0 ?

                                                                <TableContainer
                                                                    columns={columns1}
                                                                    data={dataTable1 || []}
                                                                    pageSize={5}
                                                                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                                                                    isPagination={true}
                                                                    pagination="pagination"
                                                                    buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"
                                                                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                                                                    theadClass="table-light"
                                                                /> : ''}
                                                        </Row>
                                                    </CardBody>
                                                </Card>
                                            </Col>

                                            <Col lg="3">
                                                <Row>
                                                    <h4>Approval Process</h4>
                                                    <Col lg="7">
                                                        <div className="mb-3">
                                                            <Label>Q&A</Label>
                                                            <div className="square-switch">
                                                                <input

type="checkbox"
id="negativeMark"
className="switch switch-info"
checked={negativeMark}
onClick={() => setNegativeMark(!negativeMark)}
disabled={qaToggleStatus == 0 ? true : false}
// styles={customStyles}
                                                                />
                                                                <label htmlFor="negativeMark" data-on-label="Yes" data-off-label="No" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col lg="5">
                                                        <div className="mb-3">
                                                            <Label>Study Material</Label>
                                                            <div className="square-switch">
                                                                <input
                                                                    type="checkbox"
                                                                    id="statusToggle"
                                                                    className="switch switch-info"
                                                                    checked={status}
                                                                    disabled={stToggleStatus == 0 ? true : false}
                                                                    onClick={() => setstatus(!status)}
                                                                />
                                                                <label htmlFor="statusToggle" data-on-label="Yes" data-off-label="No" />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardFooter>
                                        <div className="text-end">
                                            {user && user.user_previleges && user.user_previleges.find(f =>
                                                Number(f.urp_smid) === constants.SubmenuList.GeneralSettings && f.urp_type === constants.menuTypes.Modify) && (
                                                    <Button color="success" type="submit" className="save-user" disabled={initialSubmitState}>
                                                        Update
                                                    </Button>
                                                )}
                                            {/* <button type="button" style={{ marginLeft: "10px" }}
                                                onClick={handleClose}

                                                className="btn btn-danger">
                                                Close
                                            </button> */}
                                        </div>
                                    </CardFooter>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GeneralSettings;
