// Created by: Murugeswari Chandrabose
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState, useRef } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import * as Yup from "yup";
//import components
import Breadcrumbs from '../../../components/Common/Breadcrumb';
import TableContainer from '../../../components/Common/TableContainer';
import { ToastContainer } from "react-toastify";
import Spinners from "../../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../../components/Common/DeleteModal";
import Select from "react-select";
import { getExamBoardscategory, addNewExamBoardcategory, resetStorecategory, deleteExamBoardcategory, getmasterlist } from "../../../store/actions";
import { getLoggedInUser } from "../../../helpers/fakebackend_helper"
import * as constants from "../../../constants/layout"
import { useSelector, useDispatch } from "react-redux";
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Modal, ModalHeader, ModalBody, Form, Input, FormFeedback, Label, Card, CardBody, FormGroup, Badge } from "reactstrap";


const BoardCategoryList = () => {
  document.title = "Masters | Exam Board Category";

  const dispatch = useDispatch();
  const store = useSelector(state => state.ExamBoardCategory)
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const user = getLoggedInUser()
  const [inputFocus, setInputFocus] = useState(false);
  const inputRef = useRef(null);
  const [modal, setModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [submitbtn, setSubmitbtn] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [examBoardscategory, setexamBoardscategory] = useState([]);
  const [boardCategory, setBoardCategory] = useState(null);
  const [board, setBoard] = useState(null);
  const [selectedBoardName, setselectedBoardName] = useState(null);
  const [categoryName, setCategoryName] = useState(null);
  const [masterList, setmasterList] = useState([]);
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  const boardref = useRef(null);

  const disableButton = () => {
    setButtonDisabled(true);
  };

  const enableButton = () => {
    setButtonDisabled(false);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      categoryID: (boardCategory && board.categoryID) || '',
      categoryName: (boardCategory && board.categoryName) || '',
      selectedBoardName: (boardCategory && board.selectedBoardName) || null,

    },
    validationSchema: Yup.object({

      categoryName: Yup.string().required("Please Enter Category Name"),
      selectedBoardName: Yup.object().required("Please select Exam Board"),

    }),

    onSubmit: (values) => {
      // setSubmitbtn(true)
      setLoading(true)
      if (isEditing) { //update
        const newExamBoardcategory = {
          user_id: Number(user.uid),
          ex_board_category: values.categoryName,
          process_type: 'update',
          ex_board_id: Number(selectedBoardName.value),
          ex_board_category_id: board.exctid,
        };
        disableButton();
        // update new Exam Board
        dispatch(addNewExamBoardcategory(newExamBoardcategory));

      } else { //add
        const newExamBoardcategory = {
          user_id: Number(user.uid),
          ex_board_category: values.categoryName,
          process_type: 'save',
          ex_board_id: Number(selectedBoardName.value),
        };
        // save new Exam Board
        disableButton();
        dispatch(addNewExamBoardcategory(newExamBoardcategory));

      }

      // toggle();
    },
  });
  
  const [deleteModal, setDeleteModal] = useState(false);

  const onClickDelete = (boardcategoryData) => {
    setBoard(boardcategoryData);
    setDeleteModal(true);
  };

  const handleDeleteOrder = () => {
    setDeleteTrigger(true)
    setLoading(true)
    dispatch(deleteExamBoardcategory({ user_id: Number(user.uid), ex_board_category_id: Number(board.exctid) }))
  };

  useEffect(() => {
    if (modal && inputRef.current && inputFocus) {
      inputRef.current.focus();
      setInputFocus(false);
    }
  }, [modal, inputFocus]);

  useEffect(() => {
    if (modal) {
      setInputFocus(true);
    }
  }, [modal]);

  // After api call success stop loading
  useEffect(() => {
    enableButton()
    if (store.exboardcategory_statusFlag === 1) {
      if (submitbtn) { // add or update then refresh list        
        getExamBoardcategoryList()
        if (submitbtn !== 'savecontinue') { // If it not savecontinue close popup
          setModal(false);
          disableButton()
          enableButton()
          boardref.current.focus()
          setInputFocus(true);

        }
        setSubmitbtn('')
        validation.resetForm();
        
        // Manually trigger the onChange event for the Select component
        const event = {
          target: {
            name: 'selectedBoardName', value: {
              label: selectedBoardName.label,
              value: selectedBoardName.value,
            }
          }
        };
        validation.handleChange(event);
        // inputRef.current.focus();
        // setInputFocus(false);
      }
      if (deleteTrigger) { // delete then refresh list
        setDeleteTrigger(false)
        getExamBoardcategoryList()
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resetStorecategory({ statusFlag: 0 }));
      if (modal && inputRef.current) {
        boardref.current.focus();
      }
    } else if (store.exboardcategory_statusFlag === 2) {
      if (submitbtn) {
        setSubmitbtn('')
      }
      if (deleteTrigger) {
        setDeleteTrigger(false)
        setDeleteModal(false);
        setBoard('');
      }
      setLoading(false)
      dispatch(resetStorecategory({ statusFlag: 0 }));
    }
  }, [store.exboardcategory_statusFlag])

  const handleSelectBoardName = (selectedOption) => {
    setselectedBoardName(selectedOption);
  };

  const getmasterlistapi = () => {
    setLoading(true)
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getmasterlistapi()
  }, [dispatch]);

  useEffect(() => {
    if (store.masterlist && store.masterlist.examBoardList)
      setmasterList(store.masterlist.examBoardList)
  }, [store.masterlist])


  const getExamBoardcategoryList = () => {
    setLoading(true)
    dispatch(getExamBoardscategory({ user_id: Number(user.uid) }));
  }

  useEffect(() => {
    getExamBoardcategoryList()
    document.getElementById('headerTitle').innerHTML='Exam Board Categories';
   }, [dispatch]);

  useEffect(() => {
    setexamBoardscategory(store.examBoardscategory)
  }, [store.examBoardscategory])

  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBoardCategory && f.urp_type === constants.menuTypes.Modify) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const boardcategoryData = cellProps.row.original;
                      handleEditClick(boardcategoryData);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>)}
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.ExamBoardCategory && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const boardcategoryData = cellProps.row.original; onClickDelete(boardcategoryData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Board ▼
          </span>
        ),
        accessorKey: 'ex_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Board Category Name ▼
          </span>
        ),

        accessorKey: 'exct_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'exct_created',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'exct_updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.exct_updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                                <span>{mk_stsid}</span>

              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }
    
    ],
    []
  );


  const handleAddClick = () => {
    enableButton()
    validation.resetForm();
    setIsEditing(false)
    setBoard('')
    setselectedBoardName(null)
    toggle();
  };
  const handleEditClick = (boardcategoryData) => {
    enableButton()
    validation.resetForm();
    setBoard(boardcategoryData);
    toggle();
    setIsEditing(true);
    
    // Update this part to set the selected Exam Board and Exam Board Category Name
    validation.setValues({
      categoryName: boardcategoryData.exct_name,
      selectedBoardName: {
        label: boardcategoryData.ex_name,
        value: boardcategoryData.exct_exid,
      }
    });
    setselectedBoardName({
      label: boardcategoryData.ex_name,
      value: boardcategoryData.exct_exid,
    })
    // Manually trigger the onChange event for the Select component
    const event = {
      target: {
        name: 'selectedBoardName', value: {
          label: boardcategoryData.ex_name,
          value: boardcategoryData.exct_exid,
        }
      }
    };
    validation.handleChange(event);
  };
  const toggle = () => {
    if (modal) {
      setModal(false);
    } else {
      setModal(true);
    }
  };
  //meta title
  // document.title = "Masters | Exam Board Category";

  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.ExamBoardCategory)
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content" style={{ height: '100%' }}>
        <div className="container-fluid">
          {/* <Breadcrumbs title="Masters" breadcrumbItem="Exam Board Category" /> */}
          <Row>
            {
              isLoading ? <Spinners setLoading={setLoading} />
                :
                <Col xs="12">
                  <Card>
                    <CardBody>
                      <TableContainer
                        columns={columns}
                        data={examBoardscategory || []}
                        isGlobalFilter={true}
                        isPagination={true}
                        isAddButton={canCreate}
                        buttonName="Add"
                        SearchPlaceholder="Search..."
                        pagination="pagination"
                        handleUserClick={handleAddClick}
                        // isCustomPageSize={true}
                        //  paginationWrapper='dataTables_paginate paging_simple_numbers'

                        buttonClass="btn btn-primary waves-effect waves-light mb-2 me-2 addOrder-modal"

                        tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                        theadClass="table-light"

                        paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                      />
                    </CardBody>
                  </Card>
                </Col>
            }
          </Row>
          <Modal isOpen={modal} toggle={toggle} backdrop="static">
            <ModalHeader toggle={toggle} tag="h4">
              {!!isEditing ? "Edit Exam Board Category" : "Add Exam Board Category"}
            </ModalHeader>
            <ModalBody>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  validation.handleSubmit();
                  return false;
                }}
              >
                <Row>
                  <Col className="col-12">
                    <div className="mb-3">
                      <Label>Exam Board<span style={{ color: 'red' }}>*</span></Label>
                      <Select
                        value={selectedBoardName}
                        ref={inputRef} 
                        onChange={(selectedOption) => {
                          handleSelectBoardName(selectedOption);
                          validation.setFieldValue("selectedBoardName", selectedOption);
                        }}
                        options={masterList.map((board) => ({
                          label: board.ex_name,
                          value: board.exid,
                        }))}
                        className={`select2-selection ${validation.touched.selectedBoardName && validation.errors.selectedBoardName ? 'is-invalid' : ''}`}
                        invalid={validation.touched.selectedBoardName && validation.errors.selectedBoardName}
                      />
                      {validation.touched.selectedBoardName && validation.errors.selectedBoardName ? (
                        <FormFeedback type="invalid">{validation.errors.selectedBoardName}</FormFeedback>
                      ) : null}
                    </div>

                    <div className="mb-3">
                      <Label>Exam Board Category Name<span style={{ color: 'red' }}>*</span></Label>
                      <Input
                      
                        name="categoryName"
                        type="text"
                        placeholder="Exam Board Category"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        innerRef={boardref}
                        maxLength={constants.textboxLength.exct_name}
                        value={validation.values.categoryName || ""}
                        invalid={validation.touched.categoryName && validation.errors.categoryName ? true : false}
                      />
                      {validation.touched.categoryName && validation.errors.categoryName ? (
                        <FormFeedback type="invalid">{validation.errors.categoryName}</FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="text-end">
                      <Button color="success"
                        type="submit"
                        className="save-user"
                        onClick={() => {
                          setSubmitbtn('save')
                        }}
                        disabled={isButtonDisabled}
                      >
                        {!!isEditing ? "Update" : "Save"}
                      </Button>
                      {!isEditing && (
                        <Button color="primary" type="submit" className="save-and-continue-user"
                          onClick={() => {
                            setSubmitbtn('savecontinue')
                          }}
                          disabled={isButtonDisabled}

                          style={{ marginLeft: '10px' }}>
                          Save and Continue

                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </Form>
            </ModalBody>
          </Modal>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </React.Fragment>
  );
}
BoardCategoryList.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default BoardCategoryList;