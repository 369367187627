import { call, put, takeEvery } from "redux-saga/effects";

// Crypto Redux States
import {GET_USERMASTER, ADD_USERMASTER, DELETE_USERMASTER, RESET_PASSWORD, CHANGE_PASSWORD} from "./actionTypes";
import {getusermasterSuccess, addusermasterSuccess, deleteusermasterSuccess, resetpasswordmasterSuccess, changepasswordmasterSuccess} from "./actions";

//Include Both Helper File with needed methods
import { getusermasterList, addusermasterlist, deleteusermasterlist, resetpasswordapi, changepasswordapi, getLoggedInUser, userValidation} from "helpers/fakebackend_helper";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { useParams, Link, useNavigate } from 'react-router-dom';
// import { createBrowserHistory } from 'history';
// const history = createBrowserHistory();
function* fetchusermaster({ payload: data}) {
  try {
    const response = yield call(getusermasterList, data)
    yield call(userValidation, response)       
    yield put(getusermasterSuccess(response))  
  } catch (error) {
    yield put(getusermasterSuccess({statusFlag: 2}))
  }
}

function* fetchresetpassword({ payload: data}) {
  try {
    const response = yield call(resetpasswordapi, data)
    yield call(userValidation, response) 
    yield put(resetpasswordmasterSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
      const user = getLoggedInUser();
      if(Number(data.reset_user_id) === Number(user.uid)) {
        localStorage.removeItem("authUser");
        window.location.reload()
      }
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }  
  } catch (error) {
    yield put(resetpasswordmasterSuccess({statusFlag: 2}))
  }
}

function* fetchchangepassword({ payload: data}) {
  try {
    // const navigate = useNavigate();
    const response = yield call(changepasswordapi, data)
    yield call(userValidation, response) 
    yield put(changepasswordmasterSuccess(response))
    if (response && response.statusFlag === 1) {
      toast.success(response.message, { autoClose: 2000 });
      localStorage.removeItem("authUser");
      window.location.reload()
      // history.push('login')
    } else {
      toast.error(response.message, { autoClose: 2000 });
    }      
  } catch (error) {
    yield put(changepasswordmasterSuccess({statusFlag: 2}))
  }
}

function* onAddusermaster({ payload: data }) {
    try {
        const response = yield call(addusermasterlist, data)
        yield call(userValidation, response) 
        yield put(addusermasterSuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
          const user = getLoggedInUser();
          if(Number(data.u_uid) === Number(user.uid)) {
            localStorage.removeItem("authUser");
            window.location.reload()
          }
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(addusermasterSuccess({statusFlag: 2}))
        toast.error("USer Added Failed", { autoClose: 2000 });
    }
  }
  function* onDeleteusermaster({ payload: data }) {
    try {
        const response = yield call(deleteusermasterlist, data)
        yield call(userValidation, response) 
        yield put(deleteusermasterSuccess(response))
        console.log(response, 'response')
        if (response && response.statusFlag === 1) {
          toast.success(response.message, { autoClose: 2000 });
        } else {
          toast.error(response.message, { autoClose: 2000 });
        }      
    } catch (error) {
        yield put(deleteusermasterSuccess({statusFlag: 2}))
        toast.error("User Deleted Failed", { autoClose: 2000 });
    }
  }

function* usermasterSaga() {
  yield takeEvery(GET_USERMASTER, fetchusermaster)
  yield takeEvery(ADD_USERMASTER, onAddusermaster)
  yield takeEvery(DELETE_USERMASTER, onDeleteusermaster)
  yield takeEvery(RESET_PASSWORD, fetchresetpassword)
  yield takeEvery(CHANGE_PASSWORD, fetchchangepassword)
}

export default usermasterSaga;
