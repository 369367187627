import {
    GET_USERMASTER_SUCCESS,
    ADD_USERMASTER_SUCCESS,
    DELETE_USERMASTER_SUCCESS,
    RESET_STORE_USERMASTER,
    RESET_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_SUCCESS
} from "./actionTypes"

  const INIT_STATE = {
    userList: [],
    usermaster_statusFlag: 0
  }
  
  const Usermaster= (state = INIT_STATE, action) => {
    console.log(action.type, action.payload, 'reducer')
    switch (action.type) {
      case  GET_USERMASTER_SUCCESS:
        return {
          ...state,
          userList: action.payload.userList,
          usermaster_statusFlag: action.payload.statusFlag
        }
      case ADD_USERMASTER_SUCCESS:
      return {
          ...state,
          usermaster_statusFlag: action.payload.statusFlag,
      };
      case DELETE_USERMASTER_SUCCESS:
        return {
            ...state,
            usermaster_statusFlag: action.payload.statusFlag,
        };
        case CHANGE_PASSWORD_SUCCESS:
          return {
              ...state,
              usermaster_statusFlag: action.payload.statusFlag,
          };
        case RESET_PASSWORD_SUCCESS:
          return {
              ...state,
              usermaster_statusFlag: action.payload.statusFlag,
          };
       case RESET_STORE_USERMASTER:
            return {
              ...state,
              usermaster_statusFlag: action.payload.statusFlag,
            };
        default:
            return state
        }
      }
      
      export default Usermaster