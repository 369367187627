import {
  GET_USERROLE_LIST_SUCCESS,
  ADD_NEW_USER_ROLE_SUCCESS,
  RESET_STORE_USER_ROLE,
  EDIT_USER_ROLE,
  DELETE_USER_ROLE_SUCCESS,
  GET_USER_ROLE_DETAILS_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  userRoleList: [],
  ur_role_statusFlag: 0,
  ur_urid: 0,
  userRoleDetails: '',
  selected_ur_role_id: ''
}

const UserRole = (state = INIT_STATE, action) => {
  console.log(action.type, 'action.type', action)
  switch (action.type) {
    case GET_USERROLE_LIST_SUCCESS:
      return {
        ...state,
        userRoleList: action.payload.userRoleList,
        ur_role_statusFlag: action.payload.statusFlag
      }

    case ADD_NEW_USER_ROLE_SUCCESS:
      return {
          ...state,
          ur_role_statusFlag: action.payload.statusFlag,
          ur_urid: action.payload.ur_urid
      };
    
    case RESET_STORE_USER_ROLE:
      return {
        ...state,
        ur_role_statusFlag: action.payload.statusFlag,
        userRoleDetails: action.payload.userRoleDetails,
      };

      case DELETE_USER_ROLE_SUCCESS:
        return {
            ...state,
            ur_role_statusFlag: action.payload.statusFlag,
        };

      case GET_USER_ROLE_DETAILS_SUCCESS:
        return {
            ...state,
            ur_role_statusFlag: action.payload.statusFlag,
            userRoleDetails: action.payload.userRoleDetails
        };
        case EDIT_USER_ROLE:
          return {
            ...state,
            selected_ur_role_id: action.payload.urid,
          };
    default:
      return state
  }
}

export default UserRole
