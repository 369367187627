// Created by: Aruna Devi
// Created At: 22 Jan 2024

// src/components/filter.
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import axios from "axios";
//import components
import Breadcrumbs from '../../components/Common/Breadcrumb';
import PaginationTableContainer from '../../components/Common/PaginationTableContainer';
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Spinners from "../../components/Common/Spinner";
import { useFormik } from "formik";
import DeleteModal from "../../components/Common/DeleteModal";
import Select from "react-select";
import * as url from "../../../src/helpers/url_helper";
import { getmasterlist } from "../../store/actions";
import { getLoggedInUser,getRefreshToken } from "../../helpers/fakebackend_helper"
import { useSelector, useDispatch } from "react-redux";
import * as constants from "../../constants/layout"
//Import Flatepicker
import "./studymaterials.scss"
import "flatpickr/dist/themes/material_blue.css";
import { Button, Col, Row, UncontrolledTooltip, Container, Form, Label, Card, CardBody } from "reactstrap";

const StudyMaterials = () => {
  document.title = "Masters | Study Materials";

  const store = useSelector(state => state.Studymaterial)
  // const store = useSelector(state => state.ExamBoardCategory)

  const masterstore = useSelector(state => state.ExamBoardCategory)
  const dispatch = useDispatch();
  const user = getLoggedInUser()
  const [board, setBoard] = useState();
  const [studymaterialData, setstudymaterialData] = useState()
  const [submitbtn, setSubmitbtn] = useState(false)
  const [deleteTrigger, setDeleteTrigger] = useState(false)
  const [isLoading, setLoading] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [studymaterial, setstudymaterial] = useState([]);
  const navigate = useNavigate();
  const [canView, setCanView] = useState(false)
  const [canCreate, setCanCreate] = useState(false)
  const [canModify, setCanModify] = useState(false)
  const [canRemove, setCanRemove] = useState(false)
  const [optionBoardName, setOptionBoardName] = useState([]);
  const [optionSubjectName, setOptionSubjectName] = useState([]);
  const [optionAllSubjectName, setOptionAllSubjectName] = useState([]);
  const [optionExamPaper, setOptionExamPaper] = useState([]);
  const [optionAllExamPaper, setOptionAllExamPaper] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPageData = 8;
  const indexOfLast = currentPage * perPageData;
  const [optionTopicName, setOptionTopicName] = useState([]);
  const [optionAllTopicName, setOptionAllTopicName] = useState([]);
  const [optionStatus, setOptionStatus] = useState([]);
  const [selectedBoardName, setselectedBoardName] = useState(null);
  const [selectedTopicName, setselectedTopicName] = useState(null);
  const [selectedSubjectName, setselectedSubjectName] = useState(null);
  const [selectedExamPaper, setselectedExamPaper] = useState(null);
  const [offset, setOffset] = useState(0);
  const [generalsetting, setGeneralSetting] = useState([]);

  const [selectedStatus, setselectedStatus] = useState(null);
  const [filterData, setFilterData] = useState({
    selectedBoardName: 0,
    selectedSubjectName: 0 - 0,
    selectedTopicName: 0,
    selectedStatus: 0,
    selectedExamPaper: 0
  });
  useEffect(() => {
    if (masterstore.masterlist != undefined) {

  
        if(masterstore?.masterlist && masterstore?.masterlist?.generalsettings){
            setGeneralSetting(masterstore?.masterlist?.generalsettings)
        }

       
    }

}, [masterstore])
  useEffect(() => {

    document.getElementById('headerTitle').innerHTML = 'Study Materials';

    setselectedBoardName({
      label: "All",
      value: 0
    })
    setselectedTopicName({
      label: "All",
      value: 0
    })
    setselectedSubjectName({
      label: "All",
      value: 0
    })
    setselectedStatus({
      label: "All",
      value: 0
    })
    setselectedExamPaper({
      label: "All",
      value: 0
    })
 
  }, [dispatch]);

  useEffect(() => {

    var page_indexOfLast = currentPage * perPageData;
    var offset = page_indexOfLast - perPageData;
    GetstudymaterialList(offset);

  }, [filterData, currentPage]);

  async function GetstudymaterialList(offset) {

    const user = getLoggedInUser()


    if (filterData.selectedSubjectName != 0) {
      var [st_sid, st_sscid] = filterData.selectedSubjectName.split('-');
    } else var [st_sid, st_sscid] = [0, 0]

    const data = {
      "user_id": Number(user.uid),
      "st_exctid": Number(filterData.selectedBoardName),
      "st_expid": Number(filterData.selectedExamPaper),
      "st_sid": Number(st_sid),
      "st_sscid": Number(st_sscid),
      "st_tid": Number(filterData.selectedTopicName),
      "st_stsid": Number(filterData.selectedStatus),
      "limit": 50,
      "offset": offset,
    }
    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } }
    const response1 = await axios.post(url.BASE_URL + 'master/getstudymaterialList', data, config)
    if (response1 && response1.statusFlag === 3) { // user validation
      toast.error(response1.message, { autoClose: 2000 });
      localStorage.removeItem("authUser");
      window.location.reload()
      return
    }
    if (response1?.data?.body?.status === '401' || response1?.data?.body?.status === 401) {
      const refresh = await getRefreshToken() // Calling refreshToken if accessToken Expired
      if (refresh) {
        if (Number(refresh) === constants.invalidRefreshToken) {
          logoutUser()
        } else {
          GetstudymaterialList(offset) // calling same function again getting refresh token
        }
      }
    }
    if (response1 && response1.data && response1.data.body) {
      setstudymaterialData(response1.data.body.studymaterialList)

      setTotalCount(response1.data.body.studymaterialCount)
      return { statusFlag: response1.data.body.statusFlag, message: response1.data.body.message }
    } else {
      return { statusFlag: 2, message: 'Something went wrong' }
    }

  }



  const columns = useMemo(
    () => [
      {
        header: 'Actions',
        // accessorKey: 'action',
        // enableColumnFilter: false,
        // enableSorting: true,

        width: '8%',
        // width: (cell) => cell.value.length * 10, 
        cell: (cellProps) => {
          return (
            <div className="d-flex gap-3">
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.StudyMaterial && f.urp_type === constants.menuTypes.Create) && (
                  <Button
                    to="#"
                    className="btn btn-sm btn-soft-warning"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;

                      localStorage.setItem('isMaterialClone', '1')
                      navigate(
                        `/add-studymaterial/${cellProps.row.original.stid}`
                      )
                    }}
                  >
                    <i className="mdi mdi-content-duplicate" id="clonetooltip" />
                    <UncontrolledTooltip placement="top" target="clonetooltip">
                      Clone
                    </UncontrolledTooltip>
                  </Button>)}
                  {generalsetting && user && user.user_previleges && user.user_previleges.find(f => 
                                                            Number(f.urp_smid) === constants.SubmenuList.StudyMaterial
                                                             && 
                                                            f.urp_type === constants.menuTypes.Modify 
                                                             || f.urp_type === constants.menuTypes.Special
                                                          ) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-info"
                    onClick={() => {
                      const blueprintData = cellProps.row.original;
                      localStorage.setItem('isMaterialClone', '0')
                      navigate(`/add-studymaterial/${cellProps.row.original.stid}`);
                    }}
                  >
                    <i className="mdi mdi-pencil-outline" id="edittooltip" />
                    <UncontrolledTooltip placement="top" target="edittooltip">
                      Edit
                    </UncontrolledTooltip>
                  </div>
                ) }
              {user && user.user_previleges && user.user_previleges.find(f =>
                Number(f.urp_smid) === constants.SubmenuList.StudyMaterial && f.urp_type === constants.menuTypes.Remove) && (
                  <div
                    to="#"
                    className="btn btn-sm btn-soft-danger"
                    onClick={() => {
                      const studymaterialData = cellProps.row.original; onClickDelete(studymaterialData);
                    }}>
                    <i className="mdi mdi-delete-outline" id="deletetooltip" />
                    <UncontrolledTooltip placement="top" target="deletetooltip">
                      Delete
                    </UncontrolledTooltip>
                  </div>)}
            </div>
          );
        }
      },
      {
        header: 'S.No.',
        accessorKey: 'sno',
        width: '8%',
        enableColumnFilter: false,
        enableSorting: true,
        className: 'pl-25',
        cell: ({ row, table }) =>
          (table.getSortedRowModel()?.flatRows?.findIndex((flatRow) => flatRow.id === row.id) || 0) + 1
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Title ▼
          </span>
        ),

        accessorKey: 'st_title',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Topic ▼
          </span>
        ),

        accessorKey: 't_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Subject ▼
          </span>
        ),

        accessorKey: 's_name',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Exam Board Name & Category ▼
          </span>
        ),

        accessorKey: 'examboardnamecategory',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Languages ▼
          </span>
        ),

        accessorKey: 'langname',
        enableColumnFilter: false,
        enableSorting: true,
      },
      
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
            Created By ▼
          </span>
        ),

        accessorKey: 'created_at',
        enableColumnFilter: false,
        enableSorting: true,
      },
      {
        header: 'Status',
        accessorKey: 'st_stsid',
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const c_stsid = cellProps.row.original.stsid;

          return (
            <div className="d-flex align-items-center">
              {c_stsid == "1" ? (
                <i className="mdi mdi-check-circle text-success me-2" style={{ fontSize: '25px' }} />
              ) : c_stsid == "2"? (
                <i className="dripicons dripicons-wrong text-danger me-2" style={{ fontSize: '20px' }} />
              ) : c_stsid == "3" ? (
                <i className="mdi mdi-book-clock-outline text-warning me-2" style={{ fontSize: '25px' }}/>
              ): ""}
            </div>
          );
        },
      },
      {
        header: (
          <span style={{ cursor: 'pointer' }}>
          Updated By ▼
        </span>
        ),
        
        accessorKey: 'updated',
        // enableColumnFilter: false,
        enableColumnFilter: false,
        enableSorting: true,
        cell: (cellProps) => {
          const mk_stsid = cellProps.row.original.updated;

          return (
            <div className="d-flex align-items-center">
              {mk_stsid  ? (
                <span>{mk_stsid}</span>
              ) : (
              <span>-</span>
              )}
            </div>
          );
        },
      }

    ],
    []
  );




  useEffect(() => {
    if (masterstore.masterlist && masterstore.masterlist.board_categoryList)
      setOptionBoardName([{ categoryname: "All", exctid: "0" }, ...masterstore.masterlist.board_categoryList])
    if (masterstore.masterlist && masterstore.masterlist.subject_subcategoryList) {
      setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...masterstore.masterlist.subject_subcategoryList])
      setOptionAllSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...masterstore.masterlist.subject_subcategoryList])
    }

    if (masterstore.masterlist && masterstore.masterlist.topicList) {
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...masterstore.masterlist.topicList])
      setOptionAllTopicName([{ topicname: "All", topicid: "0" }, ...masterstore.masterlist.topicList])
    }

    if (masterstore.masterlist && masterstore.masterlist.examPaperList) {
      setOptionAllExamPaper([{ exp_name: "All", expid: "0" }, ...masterstore.masterlist.examPaperList])
      setOptionExamPaper([{ exp_name: "All", expid: "0" }, ...masterstore.masterlist.examPaperList])

    }

    if (masterstore.masterlist && masterstore.masterlist.statusList)
      setOptionStatus([{ statusname: "All", statusid: "0" }, ...masterstore.masterlist.statusList])


    

    
    
  }, [masterstore.masterlist])

  const customStyles = {
    option: (styles, state, data) => { // fixed
      return {
        ...styles,
        backgroundColor: state.isDisabled
          ? undefined
          : state.isSelected
            ? '#a6b0cf57'
            : state.isFocused
              ? '#556ee6'
              : 'white',

        color: state.isDisabled
          ? '#ccc'
          : state.isSelected
            ? 'black'
            : state.isFocused
              ? 'white'
              : 'black',

        cursor: state.isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !state.isDisabled
            ? state.isSelected
              ? '#556ee6'
              : '#556ee6'
            : undefined,
        },
        fontWeight: state.value && Number(state.value.split('-')[1]) !== 0 ? '' : '600'
      };
    }
  };
  function handleSelectBoardName(selectedBoardName) {
    setselectedBoardName(selectedBoardName);

  }
  const handleSelectPaper = (varselectedPaper) => {
    setselectedExamPaper(varselectedPaper)
    if (varselectedPaper?.value == '0' || varselectedPaper == null || varselectedPaper == undefined) { // if select all option for board, need to checko only exam paper 
      setOptionTopicName(optionAllTopicName)
      setOptionSubjectName(optionAllSubjectName)
    }
    else {
      filter_exampaper_subject(varselectedPaper)
      filter_exampaper_topic(varselectedPaper)
    }


    setselectedTopicName({
      label: "All",
      value: 0
    })
    setselectedSubjectName({
      label: "All",
      value: 0
    })

  


  };
  function filter_exampaper_subject(varselectedPaper) {
    var filtered_subject_option = optionAllSubjectName.map(e => {
      if (e.expid == varselectedPaper?.value) {
        return e;
      }

    })
    filtered_subject_option = filtered_subject_option.filter(e => e != undefined);
    setOptionSubjectName([{ s_name: "All", sid: "0", sscid: "0", exctid: "0" }, ...filtered_subject_option])

  }
  function filter_exampaper_topic(varselectedPaper) {
    var filtered_topic_option = optionAllTopicName.map(e => {
      if (e.t_expid == varselectedPaper?.value) {
        return e;
      }

    })
    filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
    setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])

  }

  function handleSelectTopicName(selectedTopicName) {
    setselectedTopicName(selectedTopicName);
  }

  function handleSelectSubjectName(varselectedSubjectName) {

    if (varselectedSubjectName?.label != 'All' && varselectedSubjectName != null && varselectedSubjectName != undefined) { // if select all option for board, need to checko only exam paper 
      handleSelectPaper(selectedExamPaper)
      const [sid, sscid] = varselectedSubjectName.value.split('-');
      var filtered_topic_option = optionAllTopicName.filter(e => e.subjectid == sid && e.subcategoryid == sscid);
      filtered_topic_option = filtered_topic_option.filter(e => e != undefined);
      setOptionTopicName([{ topicname: "All", topicid: "0" }, ...filtered_topic_option])


    }
    else {
      handleSelectPaper(selectedExamPaper)
    }

    setselectedSubjectName(varselectedSubjectName);
 
    setselectedTopicName({
      label: "All",
      value: 0
    })

  
  }
  function handleSelectStatus(varselectedStatus) {
    setselectedStatus(varselectedStatus);
  }

  const getmasterlistapi = () => {
    // setLoading(true)
    dispatch(getmasterlist({ user_id: Number(user.uid) }));
  }

  const onClickDelete = (studymaterialData) => {

    setBoard(studymaterialData.stid);
    setDeleteModal(true);
  };

  const handleDeleteOrder = async () => {
    setLoading(true);

    const data = {
      user_id: Number(user.uid),
      stid: Number(board)
    };

    const config = { headers: { authorization: 'Bearer '.concat(user.accessToken) } };

    try {
      const response = await axios.post(url.BASE_URL + 'master/deletestudymaterial', data, config);
      if (response && response.statusFlag === 3) {
        toast.error(response.message, { autoClose: 2000 });
        localStorage.removeItem("authUser");
        window.location.reload();
        return;
      }

      if (response?.data?.body?.status === '401' || response?.data?.body?.status === 401) {
        const refresh = await getRefreshToken();

        if (refresh) {
          if (Number(refresh) === constants.invalidRefreshToken) {
            logoutUser();
          } else {
            await handleDeleteOrder();
          }
        }
      }

      if (response?.data?.body?.statusFlag === 1) {
        toast.success(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      } else {
        toast.error(response.data.body.message, { position: toast.POSITION.TOP_RIGHT });
      }
    } catch (error) {
      // Handle error
      console.error(error);
    } finally {
      setLoading(false);
      setDeleteModal(false);


      await GetstudymaterialList(0);
    }
  };


  useEffect(() => {
    setFilterData({
      selectedBoardName: 0,
      selectedTopicName: 0,
      selectedSubjectName: 0 - 0,
      selectedStatus: 0,
      selectedExamPaper: 0

    })
    getmasterlistapi()

  }, [dispatch]);
  //meta title
  //document.title = "Masters | Study Materials";

  //USer role credentials
  useEffect(() => {
    if (user && user.user_previleges) {
      let page_menu_list = user.user_previleges.filter(p => Number(p.urp_smid) === constants.SubmenuList.StudyMaterial)
      if (page_menu_list) {
        setCanView(page_menu_list.find(f => f.urp_type === constants.menuTypes.View)?.urp_smid ? true : false)
        setCanCreate(page_menu_list.find(f => f.urp_type === constants.menuTypes.Create)?.urp_smid ? true : false)
        setCanModify(page_menu_list.find(f => f.urp_type === constants.menuTypes.Modify)?.urp_smid ? true : false)
        setCanRemove(page_menu_list.find(f => f.urp_type === constants.menuTypes.Remove)?.urp_smid ? true : false)
      }
    }
  }, [user]);

  return (
    <React.Fragment>
      <DeleteModal
        show={deleteModal}
        onDeleteClick={handleDeleteOrder}
        onCloseClick={() => setDeleteModal(false)}
      />
      <div className="page-content">
      <Container fluid={true}>
          {/* Render Breadcrumbs */}

          <Row>
            <Col lg={12}>

             
                  <Form>

                      <Card className="cardfirstheight">
                        <CardBody>
                          <Row>
                            <Col lg={2}>
                              <div>
                                <Label>Exam Board & Category</Label>
                                <Select
                                  value={selectedBoardName}
                                  onChange={(selectedOption) => {
                                    handleSelectBoardName(selectedOption);
                                  }}
                                  options={optionBoardName.map((option) => ({
                                    label: option.categoryname,
                                    value: option.exctid,
                                  }))}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div>
                                <Label>Exam Paper</Label>
                                <Select
                                  value={selectedExamPaper}

                                  onChange={(selectedOption) => {

                                    handleSelectPaper(selectedOption);
                                  }}
                                  options={optionExamPaper.map((option) => ({
                                    label: option.exp_name,
                                    value: option.expid,
                                  }))}


                                  className="select2-selection"

                                />


                              </div>
                            </Col>

                            <Col lg={2}>
                              <div>
                                <Label>Subject & Sub Category</Label>
                                <Select
                                  value={selectedSubjectName}
                                  onChange={(selectedOption) => {

                                    handleSelectSubjectName(selectedOption);
                                  }}
                                  options={optionSubjectName.map(option => ({
                                    label: option.s_name,
                                    value: `${option.sid}-${option.sscid}`,
                                  }))}
                                  styles={customStyles}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={2}>
                              <div>
                                <Label>Topic</Label>
                                <Select
                                  value={selectedTopicName}
                                  onChange={handleSelectTopicName}
                                  options={optionTopicName.map(option => ({
                                    label: option.topicname,
                                    value: option.topicid,
                                  }))}
                                  className="select2-selection"
                                />
                              </div>
                            </Col>
                            <Col lg={1}>

                              {/* <div className="d-flex flex-wrap gap-2 justify-content-start"> */}
                                <div>
                                  <Label>Status</Label>
                                  <Select
                                    value={selectedStatus}
                                    onChange={(selectedOption) => {
                                      handleSelectStatus(selectedOption);
                                    }}
                                    options={optionStatus.map((option) => ({
                                      label: option.statusname,
                                      value: option.statusid,
                                    }))}
                                    className="select2-selection"
                                  />
                                </div>

                                {/* <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => {

                                    setFilterData({
                                      selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                      selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                      selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0 - 0,
                                      selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                      selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0
                                    });
                                  }}
                                >
                                  Apply
                                </Button>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    setFilterData({
                                      selectedBoardName: 0,
                                      selectedTopicName: 0,
                                      selectedSubjectName: 0 - 0,
                                      selectedStatus: 0,
                                      selectedExamPaper: 0

                                    })

                                    setselectedBoardName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedTopicName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedSubjectName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedExamPaper({
                                      label: "All",
                                      value: 0
                                    })
                                  }

                                  }
                                >
                                  Clear
                                </Button>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    localStorage.setItem('isMaterialClone', '0')
                                    navigate(
                                      `/add-studymaterial/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button> */}
                              {/* </div> */}
                            </Col>
                            <Col lg={3}>
                            <div className="d-flex flex-wrap gap-2 justify-content-start"> 
                            <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={() => {

                                    setFilterData({
                                      selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                      selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                      selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0 - 0,
                                      selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                      selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0
                                    });
                                  }}
                                >
                                  Apply
                                </Button>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    setFilterData({
                                      selectedBoardName: 0,
                                      selectedTopicName: 0,
                                      selectedSubjectName: 0 - 0,
                                      selectedStatus: 0,
                                      selectedExamPaper: 0

                                    })
                                    setOptionSubjectName(optionAllSubjectName)                                
         
                                    setOptionTopicName(optionAllTopicName)
                                    setOptionExamPaper(optionAllExamPaper)
                              
                               
                              
                           

                                    setselectedBoardName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedTopicName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedSubjectName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedExamPaper({
                                      label: "All",
                                      value: 0
                                    })
                                  }

                                  }
                                >
                                  Clear
                                </Button>
                                {/* <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    localStorage.setItem('isMaterialClone', '0')
                                    navigate(
                                      `/add-studymaterial/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button> */}
                                  {user && user.user_previleges && user.user_previleges.find(f =>
                                Number(f.urp_smid) === constants.SubmenuList.StudyMaterial && f.urp_type === constants.menuTypes.Create) && (

<Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    localStorage.setItem('isMaterialClone', '0')
                                    navigate(
                                      `/add-studymaterial/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button> 
)}


                                </div>
                            </Col>
                            {/* <Col lg={2}>
                              <div className="d-flex flex-wrap gap-2 justify-content-start">

                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-info"
                                  onClick={ () => {
                                   
                                    setFilterData({
                                      selectedBoardName: selectedBoardName ? selectedBoardName.value : 0,
                                      selectedTopicName: selectedTopicName ? selectedTopicName.value : 0,
                                      selectedSubjectName: selectedSubjectName ? selectedSubjectName.value : 0 - 0,
                                      selectedStatus: selectedStatus ? selectedStatus.value : 0,
                                      selectedExamPaper: selectedExamPaper ? selectedExamPaper.value : 0
                                    });
                                  }}
                                >
                                  Apply
                                </Button>
                                <Button
                                  type="button"
                                  style={{ marginTop: 25 }}
                                  className="btn btn-soft-danger"
                                  onClick={() => {
                                    setFilterData({
                                      selectedBoardName: 0,
                                      selectedTopicName: 0,
                                      selectedSubjectName: 0 - 0,
                                      selectedStatus: 0,
                                      selectedExamPaper: 0

                                    })

                                    setselectedBoardName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedTopicName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedSubjectName({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedStatus({
                                      label: "All",
                                      value: 0
                                    })
                                    setselectedExamPaper({
                                      label: "All",
                                      value: 0
                                    })
                                  }

                                  }
                                >
                                  Clear
                                </Button>
                                <Button
                                  type="button"
                              //    style={{ marginTop: "1%", marginLeft: "58% " }}
                                  className="btn btn-secondary"
                                  onClick={() => {

                                    navigate(
                                      `/add-studymaterial/0`
                                    )
                                  }
                                  }
                                >
                                  + Add
                                </Button>
                              </div>
                            </Col> */}
                          </Row>
                        </CardBody>
                      </Card>


                  </Form>
            

              <Card className="mt-4">
                <CardBody>
                  <PaginationTableContainer
                    columns={columns}
                    data={studymaterialData || []}
                    isPagination={true}
                    isGlobalFilter={true}
                    perPageData={perPageData}
                    totalLength={totalCount}
                    currentPage={currentPage}
                    SearchPlaceholder="Search..."
                    //   setCurrentPage={setCurrentPage}
                    pagination="pagination"
                    // isCustomPageSize={true}
                    tableClass="align-middle table-nowrap dt-responsive nowrap w-100 table-check dataTable no-footer dtr-inline"
                    theadClass="table-light"
                    paginationWrapper="dataTables_paginate paging_simple_numbers pagination-rounded"
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
}
StudyMaterials.propTypes = {
  preGlobalFilteredRows: PropTypes.any,

};


export default StudyMaterials;