import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";

const Paginations = ({ perPageData, data, currentPage, setCurrentPage, isShowingPageLength, paginationDiv, paginationClass,Qnlength }) => {

    //pagination
const [start, setStart] = useState(1)
const [end, setEnd] = useState(10)
    const handleClick = (e) => {
        setCurrentPage(Number(e.target.id));
    };

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(Qnlength / perPageData); i++) {
        pageNumbers.push(i);
    }
    const handleprevPage = () => {
        let prevPage = currentPage - 1;
        setCurrentPage(prevPage);
        if(pageNumbers.length > 10)
        {
            setStart(start -  1)
            setEnd(end - 1)
        }
       
    };
    const handlenextPage = (event) => {
        // event.preventDefault();
        let nextPage = currentPage + 1;
        setCurrentPage(nextPage);
        if(pageNumbers.length > 10)
        {
        setStart(start +  1)
        setEnd(end + 1)
        }
    };

    useEffect(() => {
        if (pageNumbers.length && pageNumbers.length < currentPage) {
            setCurrentPage(pageNumbers.length)
        }
    }, [pageNumbers.length, currentPage, setCurrentPage])

    return (
        <React.Fragment>
            <Row className="justify-content-between align-items-center">
                {isShowingPageLength && <Col sm={12} md={5}>
                    <div className="text-muted dataTables_info">Showing {data?.length < perPageData ? data?.length : perPageData} of {Qnlength} entries</div>
                </Col>}
                <div className={paginationDiv}>
                    <ul className={paginationClass}>
                        <li className={`page-item ${currentPage <= 1 ? "disabled" : ''}`}>
                            <Link className="page-link" to="#" onClick={() => handleprevPage()}>
                                <i className="mdi mdi-chevron-left"></i>
                            </Link>
                        </li>
                        {(pageNumbers || []).map((item, index) => (
                         
                            <li className={currentPage === item ? "page-item active " : "page-item"} key={index}>
                                   {item >=start && item <= end ?
                                <Link className="page-link" to="#" id={item} onClick={(e) => handleClick(e)}>
                                    {item}
                                </Link>:null}
                            </li>
                        ))}
                        <li className={`page-item ${currentPage >= 1 ? "enabled" : 'disabled'}`}>
                            <Link className="page-link" to="#" onClick={(e) => handlenextPage(e)}>
                                <i className="mdi mdi-chevron-right"></i>
                            </Link>
                        </li>
                    </ul>
                </div>
            </Row>
        </React.Fragment>
    );
}

export default Paginations;